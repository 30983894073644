import "./dashboard.scss";
import SideFilters from "../../components/SideFilters/side-filters";
import ExamSection from "./sections/examSection/exam-section";
import SchoolSection from "./sections/schoolSection/school-section";
import ActiveUsers from "./sections/activeUsers/active-users";
import TuitionSection from "./sections/tuitionSection/tuition-section";
import { useSelector } from "react-redux";
import ChaptersProgressSection from "./sections/chaptersProgressSection/chaptersProgressSection";
import { useEffect, useState } from "react";
import ButtonMultipleToggle from "../../components/ButtonMultipleToggle/buttonMultipleToggle";
import ExpensesSection from "./sections/expensesSection/expenses-section";
import { getGeneralSettingValue } from "../../utils/generalUtils";
import Timologio from "./sections/timologio/timologio";

function Dashboard() {
  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const [toggleTypeNum, setToggleTypeNum] = useState(1);
  const [toggleType, setToggleType] = useState("Γενικά");

  return (
    <div className={"dashboard"}>
      <SideFilters />
      <div className="dashboard__sections">
        {profile.user_type == "admin" ? <ActiveUsers /> : ""}
        {profile.user_type == "admin" ? <SchoolSection /> : ""}
        <ExamSection />
        {(profile.user_type == "admin" && !profile.is_secretary) ||
        (profile.is_secretary &&
          getGeneralSettingValue(
            generalSettings,
            "secretary_view_tuition_stats"
          )) ? (
          <TuitionSection />
        ) : (
          ""
        )}
        {profile.user_type == "admin" &&
        (window.location.hostname.includes("nikolaidis") ||
          window.location.hostname.includes("localhost")) ? (
          <Timologio />
        ) : (
          ""
        )}
        {profile.user_type == "admin" ? <ExpensesSection /> : ""}
        {/* {profile.user_type == "admin" ? <TuitionSection /> : " "}
        
        {/* {profile.user_type == "admin" ? (
          <ButtonMultipleToggle
            button1="Γενικά"
            button2="Δίδακτρα"
            button3="Eξοδολόγιο"
            setSelected={setToggleType}
            selected={toggleTypeNum}
          ></ButtonMultipleToggle>
        ) : (
          ""
        )}
        {profile.user_type == "professor" ? <ActiveUsers /> : ""}
        {profile.user_type == "professor" ? <SchoolSection /> : ""}
        {profile.user_type == "professor" ? <ExamSection /> : ""}

        {profile.user_type == "admin" ? (
          <div>
            {toggleType == "Γενικά" ? <ActiveUsers /> : ""}
            {toggleType == "Γενικά" ? <SchoolSection /> : ""}
            {toggleType == "Γενικά" ? <ExamSection /> : ""}
            {toggleType == "Δίδακτρα" ? <TuitionSection /> : ""}
            {toggleType == "Eξοδολόγιο" ? <ExpensesSection /> : ""}
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
}

export default Dashboard;
