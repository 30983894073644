import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import { motion } from "framer-motion";
import "./reading-room.scss";
import ProfessorEventCard from "../../EventCard/exam/professor/professorEventCard";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { useHomepageContext } from "../../../pages/Homepage/homepageContext";
import ReadingRoomUser from "./readingRoomUser";

function formatDateInGreek(date) {
  const monthsInGreek = [
    "Ιανουαρίου",
    "Φεβρουαρίου",
    "Μαρτίου",
    "Απριλίου",
    "Μαΐου",
    "Ιουνίου",
    "Ιουλίου",
    "Αυγούστου",
    "Σεπτεμβρίου",
    "Οκτωβρίου",
    "Νοεμβρίου",
    "Δεκεμβρίου",
  ];

  const day = date.getDate();
  const month = monthsInGreek[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

function ReadingRoom({ event, selectedDate }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(80);
  const [modalLeft, setModalLeft] = useState(0.1);
  const [modalTop, setModalTop] = useState(0.075);

  useEffect(() => {
    if (isExpanded) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
    } else {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    if (window.innerWidth < 800) {
      setModalHeight(93);
      setModalWidth(90);
      setModalTop(0.05);
      setModalLeft(0.03);
    } else {
      setModalHeight(85);
      setModalWidth(80);
      setModalTop(0.075);
      setModalLeft(0.1);
    }
  }, [event]);

  const openCard = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;
      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
      };
      if (modalHeight) {
        newPositionStyle.height = modalHeight + "vh";
      } else {
        newPositionStyle.height = "auto";
      }
      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const cleanUpGetUsers = getUsers();
    return () => {
      cleanUpGetUsers();
    };
  }, [search]);

  const getUsers = () => {
    const uniqueId = "reading-room-users";
    const args = {
      uniqe_id: uniqueId,
      name: search,
      type: "student",
    };

    const getUsersListener = (data) => {
      setUsers(data);
    };

    const refreshUsersListener = () => {};

    socketContext.socket.on("allUsersWithParams" + uniqueId, getUsersListener);
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on("refreshAllUsersWithParams", refreshUsersListener);

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getUsersListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshUsersListener
      );
    };
  };

  const populateUsers = () => {
    return users.map((userItem) => {
      return (
        <ReadingRoomUser
          key={"readingroomuser" + userItem.user_id}
          user={userItem}
          selectedDate={selectedDate}
        />
      );
    });
  };

  return (
    <div
      ref={containerRef}
      className={
        "event-item " +
        (isExpanded ? " is-expanded " : " is-collapsed  ") +
        event.type
      }
    >
      <span>{event.type}</span>
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        initial={false}
        className={
          "event-item-content reading-room " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div className="reading-room__content">
            <div className="reading-room__header">
              <span>Αναγνωστήριο {formatDateInGreek(selectedDate)}</span>
            </div>
            <div className="reading-room__search">
              <input
                className="input"
                placeholder="Αναζήτηση μαθητή"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="reading-room__users">{populateUsers()}</div>
          </div>
        ) : (
          <div className="reading-room__preview">
            <span>Αναγνωστήριο {formatDateInGreek(selectedDate)}</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default ReadingRoom;
