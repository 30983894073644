// cookie-parser.js

/**
 * Sets a cookie with the given parameters.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} expireDays - Number of days until the cookie expires.
 * @param {string} path - The path attribute of the cookie. Defaults to "/".
 * @param {string|null} domain - The domain attribute of the cookie. Defaults to null.
 * @param {boolean} secure - Whether the cookie is secure. Defaults to false.
 * @param {string} sameSite - The SameSite attribute of the cookie. Defaults to "Lax".
 */
function setCookie(
  name,
  value,
  expireDays,
  path = "/",
  domain = null,
  secure = false,
  sameSite = "Lax"
) {
  const d = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  let cookieString = `${name}=${encodeURIComponent(
    value
  )};${expires};path=${path};SameSite=${sameSite}`;

  if (domain) {
    cookieString += `;domain=${domain}`;
  }
  if (secure) {
    cookieString += `;Secure`;
  }

  document.cookie = cookieString;
}

/**
 * Retrieves the value of a specified cookie.
 *
 * @param {string} cname - The name of the cookie to retrieve.
 * @returns {string} - The value of the cookie, or an empty string if not found.
 */
function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let c of ca) {
    c = c.trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Retrieves and parses a JSON object from a specified cookie.
 *
 * @param {string} cname - The name of the cookie to retrieve and parse.
 * @returns {object|null} - The parsed JSON object, or null if parsing fails.
 */
function getObjectCookie(cname) {
  const cookieValue = getCookie(cname);
  try {
    return JSON.parse(cookieValue);
  } catch (e) {
    return null;
  }
}

/**
 * Erases a specified cookie by setting its expiration date to the past.
 *
 * @param {string} name - The name of the cookie to erase.
 * @param {string} path - The path attribute of the cookie. Defaults to "/".
 * @param {string|null} domain - The domain attribute of the cookie. Defaults to null.
 */
function eraseCookie(name, path = "/", domain = null) {
  let cookieString = `${name}=; Max-Age=-99999999; path=${path};`;
  if (domain) {
    cookieString += `domain=${domain};`;
  }
  document.cookie = cookieString;
}

/**
 * Deletes multiple cookies by name, path, and domain.
 *
 * @param {string[]} cookies - An array of cookie names to delete.
 * @param {string[]} paths - An array of paths where the cookies might be set.
 * @param {string[]} domains - An array of domains (including subdomains) where the cookies might be set.
 */
function deleteCookies(
  cookies,
  paths = ["/"],
  domains = [window.location.hostname]
) {
  cookies.forEach((name) => {
    paths.forEach((path) => {
      domains.forEach((domain) => {
        eraseCookie(name, path, domain);
      });
    });
  });
}

/**
 * Deletes specific unwanted cookies by attempting to remove them across various paths and domains.
 */
function deleteSpecificCookies() {
  const unwantedCookies = [
    "showDouble",
    "toggleStudents",
    "toggleTests",
    "toggleParents",
    "toggleProfessors",
    "collapsedSections",
  ];

  // Define possible paths
  const paths = ["/"];
  const currentPath = window.location.pathname;
  if (currentPath !== "/") {
    paths.push(currentPath);
  }

  // Generate possible domain combinations (e.g., example.com, www.example.com)
  const hostname = window.location.hostname;
  const domainParts = hostname.split(".");
  const domains = [];

  for (let i = 0; i < domainParts.length - 1; i++) {
    const domain = domainParts.slice(i).join(".");
    domains.push(domain);
  }
  domains.push(hostname); // Include the current hostname

  // Attempt to delete unwanted cookies across all paths and domains
  deleteCookies(unwantedCookies, paths, domains);
}

export const cookieUtils = {
  setCookie,
  getCookie,
  eraseCookie,
  getObjectCookie,
  deleteCookies,
  deleteSpecificCookies,
};
