import { cookieUtils } from "./cookie-parser";

export const tokenToCookie = function (userObject, reload) {
  console.log(" user obkect ");
  console.log(userObject);
  if (userObject.birthday) {
    delete userObject.birthday;
  }

  delete userObject.grade;
  delete userObject.last_quiz;
  delete userObject.first_name;
  delete userObject.last_name;
  delete userObject.profile_picture;
  delete userObject.email;
  delete userObject.password;
  delete userObject.address;
  delete userObject.house_phone;
  delete userObject.joined_at;
  delete userObject.school;
  delete userObject.postal_code;
  delete userObject.address_area;
  delete userObject.parent_first_name;
  delete userObject.parent_first_name2;
  delete userObject.parent_last_name;
  delete userObject.parent_last_name2;
  delete userObject.work_phone;
  delete userObject.is_archived;
  delete userObject.deleted_date;
  delete userObject.school_grade;
  delete userObject.parent_phone;
  delete userObject.parent_phone2;
  delete userObject.submitted_at;
  delete userObject.accepted_terms;
  delete userObject.personal_phone;
  delete userObject.last_login;
  delete userObject.activated;
  delete userObject.parent_email2;
  delete userObject.grade;
  delete userObject.last_quiz;
  delete userObject.first_name;
  delete userObject.last_name;
  delete userObject.profile_picture;
  delete userObject.password;
  delete userObject.last_page;
  delete userObject.paid_lectures;
  delete userObject.joined_lectures;
  delete userObject.price_list_id;
  delete userObject.parent_email;
  delete userObject.grade;
  delete userObject.last_quiz;
  delete userObject.first_name;
  delete userObject.last_name;
  delete userObject.profile_picture;
  delete userObject.password;
  delete userObject.last_page;
  delete userObject.paid_lectures;
  delete userObject.address;
  delete userObject.joined_lectures;

  const cookie = JSON.stringify(userObject);
  console.log(" final cookie ");
  console.log(cookie);

  cookieUtils.setCookie("auth", cookie, 200);
  if (reload) {
    window.location.reload();
  }
};

export const removeToken = function () {
  cookieUtils.eraseCookie("auth");
};
