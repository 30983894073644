import { createSlice } from "@reduxjs/toolkit";

// Define a standalone utility function
function toDateInputValue(date) {
  if (!date) return ""; // Return an empty string if date is null or undefined

  // Attempt to create a Date object
  let dateObj = new Date(date);
  if (isNaN(dateObj)) {
    console.error("Invalid date");
    return ""; // Return an empty string if date is invalid
  }

  // Adjust for timezone offset
  dateObj.setMinutes(dateObj.getMinutes() - dateObj.getTimezoneOffset());
  return dateObj.toJSON().slice(0, 10);
}

export const navbarSlice = createSlice({
  name: "profile",
  initialState: {
    value: {},
    environmentVars: {},
    hasCameraAccess: 1,
    userTypePermissions: {},
    children: {},
    snowFall: false,
    openChats: {},
    onlineUsers: {},
    schoolLogo: null,
    generalSettings: {},
    generalValues: {},
  },
  reducers: {
    setProfileData: (state, action) => {
      let data = action.payload;
      if (data.birthday) data.birthday = toDateInputValue(data.birthday);
      state.value = data;
    },
    setSnowFall: (state, action) => {
      state.snowFall = action.payload;
    },
    setHasCameraAccess: (state, action) => {
      state.hasCameraAccess = action.payload;
    },
    setEnvironmentVars: (state, action) => {
      state.environmentVars = action.payload;
    },
    setUserTypePermissions: (state, action) => {
      state.userTypePermissions = action.payload;
    },
    setChildren: (state, action) => {
      state.children = action.payload;
    },
    setOpenChats: (state, action) => {
      state.openChats = action.payload;
    },
    setSchoolLogo: (state, action) => {
      state.schoolLogo = action.payload;
    },
    closeOpenChat: (state, action) => {
      const chatId = action.payload;
      if (state.openChats[chatId]) {
        delete state.openChats[chatId];
      }
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    setGeneralSettings: (state, action) => {
      state.generalSettings = action.payload;
    },
    setGeneralValues: (state, action) => {
      state.generalValues = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProfileData,
  setHasCameraAccess,
  setEnvironmentVars,
  setChildren,
  setUserTypePermissions,
  setSnowFall,
  setOpenChats,
  closeOpenChat,
  setOnlineUsers,
  setSchoolLogo,
  setGeneralSettings,
  setGeneralValues,
} = navbarSlice.actions;

export default navbarSlice.reducer;
