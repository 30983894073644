import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiX,
  BiArrowFromRight,
  BiArrowFromLeft,
  BiCarousel,
  BiSearch,
  BiCategoryAlt,
  BiCheckCircle,
  BiCast,
  BiTime,
  BiLogInCircle,
  BiCheck,
  BiLockAlt,
  BiCaretRightCircle,
} from "react-icons/bi";
import { motion } from "framer-motion";
import "./parentExams.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import { iconStyle } from "../../../../utils/generalUtils";
import { EventProvider } from "../../../../components/ConfigureEvent/eventContext";
// import ParentExamsCard from "./parentExamsCard";
import ParentExamsCard from "./parentExamsCard";
import { useSelector } from "react-redux";
import LoaderCard from "../../../../components/LoaderCard/loaderCard";

function ParentExams({
  allExams,
  searchText,
  setSearchText,
  topFilter,
  setTopFilter,
  gotExams,
}) {
  const socketContext = useContext(SocketContext);

  const [searchOpen, setSearchOpen] = useState(false);

  const sideFiltersExamType = useSelector((state) => state.topFilter.value);

  const populateExams = () => {
    return allExams.map((exam, index) => {
      return (
        <ParentExamsCard key={"professorExamsCard" + index} event={exam} />
      );
    });
  };

  const searchClick = () => {
    if (!searchOpen) {
      setSearchOpen(true);
    }
  };

  return (
    <div className="student-exams">
      <div className="student-exams__header">
        <div
          className={
            "filters " +
            (sideFiltersExamType == "online-exam" ? " online-exam " : "") +
            (searchOpen ? " search-open " : "")
          }
        >
          <div
            className={
              "filters__item start " + (topFilter == "all" ? "selected" : "")
            }
            onClick={() => setTopFilter("all")}
          >
            <span>Όλα</span>
          </div>
          <div
            className={
              "filters__item " + (topFilter == "marked" ? "selected" : "")
            }
            onClick={() => setTopFilter("marked")}
          >
            <BiCheckCircle
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>Βαθμολογημένα</span>
          </div>
          <div
            className={
              "filters__item " + (topFilter == "not_marked" ? "selected" : "")
            }
            onClick={() => setTopFilter("not_marked")}
          >
            <BiTime
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>
              {window.location.hostname.includes("simmetria") ||
              window.location.hostname.includes("localhost")
                ? "Ανεπαρκές"
                : "Αβαθμολόγητο"}
            </span>
          </div>
          {sideFiltersExamType == "online-exam" ? (
            <div
              className={
                "filters__item " +
                (topFilter == "in-proogress" ? "selected" : "")
              }
              onClick={() => setTopFilter("in-proogress")}
            >
              <BiLogInCircle
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Σε Εξέλιξη</span>
            </div>
          ) : (
            ""
          )}
          {sideFiltersExamType == "online-exam" ? (
            <div
              className={
                "filters__item " + (topFilter == "open" ? "selected" : "")
              }
              onClick={() => setTopFilter("open")}
            >
              <BiCaretRightCircle
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Ανοιχτά</span>
            </div>
          ) : (
            ""
          )}
          {sideFiltersExamType == "online-exam" ? (
            <div
              className={
                "filters__item " + (topFilter == "locked" ? "selected" : "")
              }
              onClick={() => setTopFilter("locked")}
            >
              <BiLockAlt
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <span>Κλειδωμένα</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={"search " + (searchOpen ? "open" : "closed")}
          onClick={() => searchClick()}
        >
          <input
            className="input"
            placeholder="Αναζητήστε Εξέταση"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchOpen && window.innerWidth > 800 ? (
            <BiX
              className="search-close"
              onClick={() => setSearchOpen(false)}
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          ) : (
            <BiSearch
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          )}
        </div>
      </div>
      <div className="student-exams__list">
        {gotExams ? (
          populateExams()
        ) : window.innerWidth > 800 ? (
          <LoaderCard cardSum={16} width={25} />
        ) : (
          <LoaderCard cardSum={10} width={100} />
        )}
      </div>
    </div>
  );
}

export default ParentExams;
