import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../../app/socket";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronRight,
  BiDesktop,
  BiDotsHorizontal,
} from "react-icons/bi";
import {
  getGeneralSettingValue,
  iconStyle,
} from "../../../../../utils/generalUtils";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import DropDownMenu from "../../../../DropDownMenu/dropDownMenu";

function CommentItem({
  event,
  student,
  comment,
  selectedDate,
  lectureStudentProgress,
}) {
  const [grades, setGrades] = useState([]);
  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const socketContext = useContext(SocketContext);

  const [progressValue, setProgressValue] = useState();
  const [understandingValue, setUnderstandingValue] = useState();

  const [progressLabels, setProgressLabels] = useState([
    "Καμία Προσπάθεια",
    "Ελάχιστη Προσπάθεια",
    "Μέτρια Προσπάθεια",
    "Καλή Προσπάθεια",
    "Άριστη Προσπάθεια",
  ]);

  useEffect(() => {
    if (lectureStudentProgress && lectureStudentProgress.length) {
      lectureStudentProgress.map((prog) => {
        if (prog.type == "understanding") {
          setUnderstandingValue(prog.progress_grade);
        }
        if (prog.type == "progress") {
          setProgressValue(prog.progress_grade);
        }
      });
      if (!lectureStudentProgress.find((prog) => prog.type == "progress")) {
        setProgressValue();
      }
      if (
        !lectureStudentProgress.find((prog) => prog.type == "understanding")
      ) {
        setUnderstandingValue();
      }
    } else {
      setUnderstandingValue();
      setProgressValue();
    }
  }, [lectureStudentProgress]);

  useEffect(() => {
    if (window.location.hostname.includes("oramapaideias")) {
      if (
        [109, 10, 13, 16, 18, 21, 22, 24, 26, 28, 30, 31, 29, 17].includes(
          event.class_id
        )
      ) {
        setProgressLabels([
          "You can do better",
          "Good",
          "Quite Good",
          "Very Good",
          "Excellent",
        ]);
      }
    }
  }, []);

  const [studentProgress, setStudentProgress] = useState(
    lectureStudentProgress ? lectureStudentProgress : ""
  );

  useEffect(() => {
    if (student.user_id == 1757) {
      console.log(" total progress ");
      console.log(lectureStudentProgress);
    }
    if (!lectureStudentProgress) {
      setStudentProgress();
    }
  }, [lectureStudentProgress]);

  const [commentText, setCommentText] = useState("");
  const [commentScore, setCommentScore] = useState(35);

  const saveComment = () => {
    if (commentText) {
      let body = {
        to_user_id: student.user_id,
        from_user_id: profile.user_id,
        title: event.title,
        description: commentText,
        class_id: event.class_id,
        notify_parent: true,
        notify_student: true,
        student_email: student.email,
        event_id: event.event_id,
        created_at: selectedDate,
      };

      setCommentText("");
      socketContext.socket.emit("saveProfileComment", body);
    }
  };

  const updateLectureStudentProgress = (newProg, type) => {
    let body = {
      student_id: student.user_id,
      student_email: student.email,
      event_id: event.event_id,
      created_at: selectedDate,
      progress_grade: newProg,
      type: type,
    };

    socketContext.socket.emit("updateLectureStudentProgress", body);
  };

  const deleteComment = () => {
    let body = {
      comment_id: comment.profile_comments_id,
      event_id: event.event_id,
    };

    socketContext.socket.emit("deleteProfileComment", body);
  };

  const populateProgressItems = (type) => {
    let prog = progressValue;
    if (type == "understanding") {
      prog = understandingValue;
    }
    return progressLabels.map((progLabel, index) => {
      return (
        <div key={"progress-item" + index} className="progress-item">
          <div className="progress-item__label">
            <span>{progLabel}</span>
          </div>
          <div
            className={
              "progress-item__line " +
              (prog > index + 1 ? "full-active" : "") +
              (prog == index + 1 ? "half-active" : "")
            }
          >
            <div
              onClick={() => {
                let newProg = index + 1;
                updateLectureStudentProgress(newProg, type);
                if (type == "understanding") {
                  setUnderstandingValue(newProg);
                } else {
                  setProgressValue(newProg);
                }
              }}
              className={
                "progress-circle " + (prog >= index + 1 ? "active" : "")
              }
            ></div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={"comment-item"}>
      <Link
        to={"/profile?user-id=" + student.user_id}
        className="comment-item__header"
      >
        <div className="header__img">
          <img
            src={
              student.profile_picture
                ? student.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="header__info">
          <div className="header__info-main">
            <span className="name">
              <span>{student.first_name}</span>
              <span>{student.last_name}</span>
            </span>
            <span className="date">{}</span>
          </div>
          <div className="header__info-details"></div>
        </div>
      </Link>
      <div className="comment-item__text">
        <div className="comment-item__text-input">
          {getGeneralSettingValue(
            generalSettings,
            "lecture_student_progress"
          ) ? (
            <div className="wrapper-progress">
              {getGeneralSettingValue(
                generalSettings,
                "lecture_student_understanding"
              ) ? (
                <span className="wrapper-progress__title">Επίδοση</span>
              ) : (
                ""
              )}
              <div className="wrapper-progress__list no-scrollbar">
                {populateProgressItems("progress")}
              </div>
            </div>
          ) : (
            ""
          )}
          {getGeneralSettingValue(
            generalSettings,
            "lecture_student_understanding"
          ) ? (
            <div className="wrapper-progress">
              <span className="wrapper-progress__title">
                {window.location.hostname.includes("oramapaideias") ||
                window.location.hostname.includes("localhost")
                  ? "Προφορικός"
                  : "Κατανόηση"}
              </span>
              <div className="wrapper-progress__list no-scrollbar">
                {populateProgressItems("understanding")}
              </div>
            </div>
          ) : (
            ""
          )}
          {comment ? (
            <div className="comment-item__text-content">
              <span>{comment.description}</span>
              <div className="comment-item__text-content-actions">
                <DropDownMenu onAction={deleteComment} />
              </div>
            </div>
          ) : (
            <div className="wrapper-input">
              <input
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                placeholder="Εισάγεται σχόλια για την επίδοση του μαθητή στο μάθημα..."
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevents the default action of Enter key in a form
                    saveComment();
                  }
                }}
              />
              <BiChevronRight
                onClick={() => saveComment()}
                size={"25px"}
                color={"#6225e6"}
                style={iconStyle("transparent")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommentItem;
