import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronDown,
  BiChevronUp,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { CircularProgressbar } from "react-circular-progressbar";

function GeneralTestUser({ user, selectedDate, test, classItem }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [isEdit, setIsEdit] = useState(false);
  const [newGrade, setNewGrade] = useState(test.grade);
  const [newComment, setNewComment] = useState(test.comment);

  const editTest = () => {
    const body = {
      general_test_id: test.general_test_id,
      grade: newGrade,
      comment: newComment,
    };

    socketContext.socket.emit("editGeneralTest", body);
    setIsEdit(false);
  };

  return (
    <div className={"students-item "}>
      <div className="students-item__personal">
        <div className="students-item__personal-img">
          <img
            src={
              user.profile_picture
                ? user.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="students-item__personal-name">
          <span>{user.first_name}</span>
          <span>{user.last_name}</span>
        </div>
      </div>
      <div className="students-item__class">
        <span>{classItem.class_name}</span>
      </div>
      <div className="students-item__grade">
        <CircularProgressbar
          pathColor="{red}"
          strokeWidth={6}
          className={"users__item-input-total-circle"}
          value={(100 / 20) * newGrade}
          duration={1.4}
          text={newGrade + "/" + 20}
        />
        {isEdit ? (
          <div className="score-item">
            <span>Βαθμός</span>
            <div className="score-item__input">
              <BiChevronDown
                size={"30px"}
                color={"#fff"}
                style={iconStyle("#d6d6d6")}
                onClick={() => setNewGrade(parseInt(newGrade) - 1)}
              />
              <input
                type="number"
                value={newGrade}
                onChange={(e) => setNewGrade(e.target.value)}
              />
              <BiChevronUp
                size={"30px"}
                color={"#fff"}
                style={iconStyle("#d6d6d6")}
                onClick={() => setNewGrade(parseInt(newGrade) + 1)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="students-item__comment">
        <span className="comment-label">Γενικά Σχόλια</span>
        {isEdit ? (
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className="input"
          ></textarea>
        ) : (
          <span className="comment-title">
            {test.comment ? test.comment : "Δεν υπάρχουν σχόλια"}
          </span>
        )}
      </div>
      {profile.user_type == "admin" || profile.user_type == "professor" ? (
        !isEdit ? (
          <div className="students-item__edit">
            <button className="cta" onClick={() => setIsEdit(true)}>
              Επεξεργασία
            </button>
          </div>
        ) : (
          <div className="students-item__save">
            <button className="cta cta-red" onClick={() => setIsEdit(false)}>
              Ακύρωση
            </button>
            <button className="cta" onClick={() => editTest()}>
              Αποθήκευση
            </button>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default GeneralTestUser;
