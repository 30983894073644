import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiPlus, BiUpload, BiX } from "react-icons/bi";
import {
  getGeneralSettingValue,
  iconStyle,
} from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import { useSelector } from "react-redux";
import Compress from "compress.js";
import Select from "react-select";

const kadValues = [
  {
    id: 1,
    value: "ΕΛΛΕΙΨΗ ΔΡΑΣΤΗΡΙΟΤΗΤΑΣ, ΛΟΓΩ ΜΗ ΔΡΑΣΤΗΡΙΟΠΟΙΗΣΗΣ ΜΕΧΡΙ ΣΗΜΕΡΑ",
  },
  { id: 2, value: "ΕΛΛΕΙΨΗ ΔΡΑΣΤΗΡΙΟΤΗΤΑΣ, ΛΟΓΩ ΑΔΡΑΝΟΠΟΙΗΣΗΣ" },
  {
    id: 3,
    value: "ΕΛΛΕΙΨΗ ΔΡΑΣΤΗΡΙΟΤΗΤΑΣ, ΛΟΓΩ ΔΡΑΣΤΗΡΙΟΠΟΙΗΣΗΣ ΜΟΝΟ ΕΚΤΟΣ ΕΛΛΑΔΑΣ",
  },
  { id: 4, value: "ΑΓΡΟΤΗΣ ΕΙΔΙΚΟΥ ΚΑΘΕΣΤΩΤΟΣ" },
];

const options = kadValues.map((kad) => ({
  value: kad.id, // Using 'id' as the unique value
  label: kad.value, // Using 'value' as the label to display
}));

function AddTimologio({ isExpanded, setIsExpanded }) {
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [vat, setVat] = useState("");
  const [doy, setDoy] = useState("");
  const [vatRecepient, setVatRecepient] = useState("");
  const [address, setAddress] = useState("");
  const [addressNumber, setAddressNumber] = useState();
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState();
  const [selectedKad, setSelectedKad] = useState();
  const [country, setCountry] = useState("GR");
  const [code, setCode] = useState();
  const [companyName, setCompanyName] = useState("");
  const [recepientMail, setRecepientMail] = useState("");
  const [recepientPhone, setRecepientPhone] = useState("");

  const [aadeKey, setAadeKey] = useState();
  const [aadeId, setAadeId] = useState();
  const [schoolName, setSchoolName] = useState();
  const [schoolEmail, setSchoolEmail] = useState();

  const [kadSearch, setKadSearch] = useState("");

  const [latestAa, setLatestAa] = useState(0);

  const [amount, setAmount] = useState();

  const generalValues = useSelector((state) => state.profile.generalValues);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // if (generalValues.find((val) => val.name == "aade-id")) {
    //   setAadeId(generalValues.find((val) => val.name == "aade-id"));
    // }
    // if (generalValues.find((val) => val.name == "school-name")) {
    //   setSchoolName(generalValues.find((val) => val.name == "school-name"));
    // }
    // if (generalValues.find((val) => val.name == "school-email")) {
    //   setSchoolEmail(generalValues.find((val) => val.name == "school-email"));
    // }
    // if (generalValues.find((val) => val.name == "school-vat")) {
    //   setVat(generalValues.find((val) => val.name == "school-vat"));
    // }
    // if (generalValues.find((val) => val.name == "aade-key")) {
    //   setAadeKey(generalValues.find((val) => val.name == "aade-key"));
    // }
  }, [generalValues]);

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.05 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "90vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    const cleanUpgetLatestAa = getLatestAa();
    return () => {
      cleanUpgetLatestAa();
    };
  }, []);

  const getLatestAa = () => {
    let args = {};

    const getLatestAaListener = (data) => {
      console.log(data);
      if (data && data[0] && data[0].latest_aa && data[0].latest_aa > 0) {
        setLatestAa(data[0].latest_aa);
      } else {
        console.log("setting it to 0");
        setLatestAa(0);
      }
    };

    const refreshLatestAaListener = () => {
      socketContext.socket.emit("getLatestAaInvoice", args);
    };

    socketContext.socket.on("latestAaInvoice", getLatestAaListener);
    socketContext.socket.emit("getLatestAaInvoice", args);
    socketContext.socket.on("refreshLatestAa", refreshLatestAaListener);

    return () => {
      socketContext.socket.off("getLatestAaInvoice", getLatestAaListener);
      socketContext.socket.off("latestAaInvoice", getLatestAaListener);
      socketContext.socket.off("refreshLatestAa", refreshLatestAaListener);
    };
  };

  // const populateKadValues = () => {
  //   return kadValues
  //     .filter((kd) => kd.value.toLowerCase().includes(kadSearch.toLowerCase()))
  //     .map((kad, index) => {
  //       return (
  //         <div key={"kad-value" + index} className="kad-list__item">
  //           <span className="kad-list__item-label">{kad.value}</span>
  //         </div>
  //       );
  //     });
  // };

  const handleChange = (option) => {
    setSelectedKad(option);
    console.log("Selected option:", option); // You can access the selected id and value here
  };

  const sendInvoice = async () => {
    // setLoadingAade(true);
    let actualAa = parseInt(latestAa) + 1;
    // const data = {
    //   amount: payment.amount + ".0",
    //   is_cash: true,
    //   aade_id: aadeId,
    //   aade_key: aadeKey,
    //   vat: vat,
    //   series: series,
    //   aa: actualAa,
    //   payment_method: payment.payment_method ? payment.payment_method : "cash",
    // };
    // fetch(calls.sendReceipt, {
    //   method: "post",
    //   body: JSON.stringify(data),
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: tokenUtils.getBearerToken(),
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data && data.status == 200) {
    //       setShowSuccess(true);
    //       let xmlData = {};
    //       const parser = new DOMParser();
    //       const xmlDoc = parser.parseFromString(data.data, "text/xml");
    //       const response = xmlDoc.getElementsByTagName("response")[0];
    //       const newObject = {
    //         index: response.getElementsByTagName("index")[0].textContent,
    //         invoiceUid:
    //           response.getElementsByTagName("invoiceUid")[0].textContent,
    //         invoiceMark:
    //           response.getElementsByTagName("invoiceMark")[0].textContent,
    //         qrUrl: response.getElementsByTagName("qrUrl")[0].textContent,
    //         statusCode:
    //           response.getElementsByTagName("statusCode")[0].textContent,
    //       };
    //       xmlData = newObject;
    //       let body = {
    //         payment_id: payment.student_payments_id,
    //         invoice_uid: xmlData.invoiceUid,
    //         invoice_mark: xmlData.invoiceMark,
    //         qrUrl: xmlData.qrUrl,
    //         series: series,
    //         aa: actualAa,
    //       };
    //       socketContext.socket.emit("addAadePayment", body);
    //       setTimeout(() => {
    //         setShowSuccess(false);
    //         setConfirmAade(false);
    //       }, 1500);
    //     } else {
    //       setConfirmAade(false);
    //     }
    //   });
  };

  const saveTempTimologio = () => {
    let body = {
      afm: vatRecepient,
      doy: doy,
      kad: selectedKad.value,
      code: code,
      company_name: companyName,
      address: address,
      address_number: addressNumber,
      email: recepientMail,
      phone: recepientPhone,
      amount: amount,
    };

    socketContext.socket.emit("addTempInvoice", body);
    setIsExpanded(false);
  };

  return (
    <div
      ref={containerRef}
      className={
        "add-timologio " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "add-timologio__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="add-timologio__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="add-timologio__config-content">
              <span className="content-title">Παραλήπτης</span>
              <div className="kad">
                <span className="kad-title">Τύπος Πελάτη</span>
                {/* <div className="kad-search">
                  <input
                    className="input"
                    value={kadSearch}
                    onChange={(e) => setKadSearch(e.target.value)}
                    placeholder="Τύπος Πελάτη"
                  />
                </div> */}
                <div className="kad-list">
                  <Select
                    className="input"
                    value={selectedKad}
                    onChange={handleChange}
                    options={options}
                    isSearchable={true} // Search enabled by default
                    placeholder="Επιλέξτε επάγγελμα..." // Placeholder text in Greek
                  />
                </div>
              </div>
              <div className="content-item double">
                <div className="content-item">
                  <span>ΑΦΜ</span>
                  <input
                    className="input"
                    value={vatRecepient}
                    onChange={(e) => setVatRecepient(e.target.value)}
                    placeholder="ΑΦΜ"
                  />
                </div>
                <div className="content-item">
                  <span>ΔΟΥ</span>
                  <input
                    className="input"
                    value={doy}
                    onChange={(e) => setDoy(e.target.value)}
                    placeholder="ΔΟΥ"
                  />
                </div>
              </div>
              <div className="content-item double">
                <div className="content-item">
                  <span>Κωδικός</span>
                  <input
                    className="input"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Κωδικός"
                  />
                </div>
                <div className="content-item">
                  <span>Επωνυμία Πελάτη</span>
                  <input
                    className="input"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Επωνυμία Πελάτη"
                  />
                </div>
              </div>
              <div className="content-item">
                <span>Οδός</span>
                <div className="double-input">
                  <input
                    className="input"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Οδός"
                  />
                  <input
                    className="input"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    placeholder="20"
                  />
                </div>
              </div>
              <div className="content-item">
                <span>Περιοχή</span>
                <div className="double-input">
                  <input
                    className="input"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Περιοχή"
                  />
                  <input
                    className="input"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    placeholder="T.X."
                  />
                </div>
              </div>
              {/* <div className="content-item">
                <span>Ταχυδρομικός Κώδικας</span>
                <input
                  className="input"
                  value={postalCode}
                  onChagne={(e) => setPostalCode(e.target.value)}
                  placeholder="T.X."
                />
              </div> */}
              <div className="content-item double">
                <div className="content-item">
                  <span>Email</span>
                  <input
                    className="input"
                    value={recepientMail}
                    onChange={(e) => setRecepientMail(e.target.value)}
                    placeholder="email"
                  />
                </div>
                <div className="content-item">
                  <span>Τηλέφωνο</span>
                  <input
                    className="input"
                    value={recepientPhone}
                    onChange={(e) => setRecepientPhone(e.target.value)}
                    placeholder="Τηλέφωνο"
                  />
                </div>
              </div>
              <div className="content-item">
                <span>Ποσό</span>
                <input
                  className="input"
                  value={amount}
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="30€"
                />
              </div>
            </div>
            <div className="add-timologio__config-item save">
              <button className="cta" onClick={() => saveTempTimologio()}>
                Αποθήκευση
              </button>
            </div>
            <div
              className="add-timologio__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="add-timologio__preview">
            <BiPlus
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>Έκδοση Τιμολόγιου</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default AddTimologio;
