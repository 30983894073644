import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../../../../app/socket";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronDown,
  BiChevronUp,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import "./mark-student.scss";
import { CircularProgressbar } from "react-circular-progressbar";

function MarkStudent({ student, selectedDate, classId }) {
  const socketContext = useContext(SocketContext);
  const [grade, setGrade] = useState(0);
  const [comment, setComment] = useState("");
  const [saved, setSaved] = useState(false);

  const saveGrade = () => {
    setSaved(true);
    const body = {
      student_id: student.user_id,
      comment: comment,
      grade: grade,
      class_id: classId,
      selected_date: selectedDate,
    };

    socketContext.socket.emit("markGeneralTest", body);
  };

  return (
    <div className={"mark-student " + (saved ? "saved" : "")}>
      <div className="mark-student__personal">
        <div className="mark-student__personal-img">
          <img
            src={
              student.profile_picture
                ? student.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="mark-student__personal-name">
          <span>{student.first_name}</span>
          <span>{student.last_name}</span>
        </div>
      </div>
      <div className="mark-student__grade">
        <div className="grade-wrapper">
          <CircularProgressbar
            pathColor="{red}"
            strokeWidth={6}
            className={"users__item-input-total-circle"}
            value={(100 / 20) * grade}
            duration={1.4}
            text={grade + "/" + 20}
          />
          <div className="score-item">
            <span>Βαθμός</span>
            <div className="score-item__input">
              <BiChevronDown
                size={"30px"}
                color={"#fff"}
                style={iconStyle("#d6d6d6")}
                onClick={() => setGrade(parseInt(grade) - 1)}
              />
              <input
                type="number"
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              />
              <BiChevronUp
                size={"30px"}
                color={"#fff"}
                style={iconStyle("#d6d6d6")}
                onClick={() => setGrade(parseInt(grade) + 1)}
              />
            </div>
            {/* <div className="score-item__max">
              <span>Μεγ. Βαθμός: {getThemaMax(index)}</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mark-student__comment">
        <span className="mark-student__comment-label">Γενικό Σχόλιο</span>
        <textarea
          className="input"
          type="text"
          placeholder="Αφήστε ένα γενικό σχόλιο επίδοσης"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <button className="cta" onClick={() => saveGrade()}>
          Αποθήκευση
        </button>
      </div>
    </div>
  );
}

export default MarkStudent;
