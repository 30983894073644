import React, { useEffect, useState, useRef, useContext } from "react";
import "./chat-item.scss";
import { BiX, BiSend, BiArrowBack, BiPencil } from "react-icons/bi";
import { SocketContext } from "../../app/socket";
import { useDispatch, useSelector } from "react-redux";
import { closeOpenChat } from "../Navbar/navbarSlice";
// import newMessageSound from "../../resources/sound-effects/new-message-sound.mp3";
import GifBox from "./gifBox";
import ChatEdit from "./chatEdit";
import { iconStyle } from "../../utils/generalUtils";
import { useChatContext } from "../../pages/WithNav/chatContext";
import { ChatContext } from "./chatProvider";

function formatTimestamp(timestamp) {
  // Parse the timestamp and create a Date object
  const timestampDate = new Date(timestamp);
  const now = new Date();

  // Reset the time part for both dates to ensure accurate day difference calculation
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const inputDate = new Date(
    timestampDate.getFullYear(),
    timestampDate.getMonth(),
    timestampDate.getDate()
  );

  // Calculate the difference in days
  const diffTime = today - inputDate;
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  // Define Greek day names
  const dayNamesGreek = [
    "Κυριακή",
    "Δευτέρα",
    "Τρίτη",
    "Τετάρτη",
    "Πέμπτη",
    "Παρασκευή",
    "Σάββατο",
  ];

  if (diffDays === 0) {
    // If the timestamp is today, return the time
    return timestampDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (diffDays > 0 && diffDays <= 6) {
    // If the timestamp is within the last 6 days, return the day in Greek and the time
    const dayInGreek = dayNamesGreek[timestampDate.getDay()];
    const time = timestampDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${dayInGreek}, ${time}`;
  } else {
    // If the timestamp is more than 6 days old, return the year, month as a number, day, and time
    const year = timestampDate.getFullYear();
    const month = timestampDate.getMonth() + 1; // JavaScript months are 0-indexed, so add 1 for human-readable format
    const day = timestampDate.getDate();
    const time = timestampDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }, ${time}`;
  }
}

function ChatItem({ chat: chatProp, userId, navigateBack }) {
  const [chat, setChat] = useState(chatProp);
  const [chatImage, setChatImage] = useState("");
  const profile = useSelector((state) => state.profile.value);

  const { closeChatId, updateChatId } = useChatContext();

  const [isEdit, setIsEdit] = useState(false);
  const editRef = useRef(null);

  useEffect(() => {
    setChat(chatProp);

    if (chatProp.is_group_chat && !chat.chat_picture) {
      setChatImage("/resources/group-chat.png");
    } else {
      if (chat.chat_picture) {
        setChatImage(chat.chat_picture);
      } else {
        setChatImage("/resources/student.png");
      }
    }
  }, [chatProp]);

  const dispatch = useDispatch();
  const socketContext = useContext(SocketContext);

  // const newMessageAudio = new Audio(newMessageSound);

  const newMessageRef = useRef(null);

  const messageContainerRef = useRef(null);

  const [focused, setFocused] = useState(false);

  const [messageStatus, setMessageStatus] = useState("");

  const [chatIsOpen, setChatIsOpen] = useState(true);
  const [newMessage, setNewMessage] = useState("");

  const [chatMessages, setChatMessages] = useState([]);
  const chatMessagesRef = useRef(0);
  const [messageReceipts, setMessageReceipts] = useState([]);

  const [readLastMsg, setReadLastMsg] = useState(false);

  const [isTyping, setIsTyping] = useState(false);
  const typingTimer = useRef(null);

  const resetTyping = () => {
    setIsTyping(false);
  };

  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const isScrolledToBottom = (containerRef, offset = 1000) => {
    if (!containerRef.current) {
      return false;
    }

    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    return scrollTop + clientHeight + offset >= scrollHeight;
  };

  useEffect(() => {
    if (chatMessagesRef.current <= 1) {
      chatMessagesRef.current++;
      scrollToBottom();
    }
  }, [chatMessages]);

  const checkImagesLoaded = () => {
    const images = messageContainerRef.current.querySelectorAll("img");

    // Map each image to a promise that resolves when the image has loaded
    const loadPromises = Array.from(images).map(
      (img) =>
        new Promise((resolve) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = resolve; // Resolve on error to not block scrolling if an image fails to load
          }
        })
    );

    // Use Promise.all to wait for all promises to resolve
    Promise.all(loadPromises)
      .then(() => {
        scrollToBottom(); // Scroll to bottom once all images have loaded
      })
      .catch((error) => {
        console.error("Error waiting for images to load: ", error);
      });
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      const images = messageContainerRef.current.querySelectorAll("img");
      const allLoaded = Array.from(images).every((img) => img.complete);

      if (allLoaded) {
        scrollToBottom();
      }
      const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === "childList") {
            scrollToBottom();
            break; // Exit after the first relevant mutation
          }
        }
      });

      // Start observing the container for child list changes
      observer.observe(messageContainerRef.current, {
        childList: true, // Observe direct children additions/removals
        subtree: true, // Observe all descendants
      });

      return () => observer.disconnect();
    }
  }, [messageContainerRef]);

  useEffect(() => {
    if (messageContainerRef.current) {
      const observer = new MutationObserver((mutations) => {
        let shouldCheckImages = mutations.some(
          (mutation) => mutation.addedNodes.length > 0
        );
        if (shouldCheckImages) {
          checkImagesLoaded(); // Check and wait for images to load on new mutations
        }
      });

      observer.observe(messageContainerRef.current, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }
  }, [messageContainerRef]);

  useEffect(() => {
    if (focused) {
      // newMessageRef.current.focus();
    }
    if (focused && !readLastMsg)
      if (chatMessages.length) {
        const lastMessage = chatMessages[chatMessages.length - 1];
        if (lastMessage.sender_id !== userId) {
          sendMessageReceipt(lastMessage.message_id);
        }
      }
  }, [focused]);

  useEffect(() => {
    if (chatMessagesRef.current > 0) {
      sendMessageTyping();
    }
  }, [newMessage]);

  const [sentNewMsg, setSentNewMsg] = useState(false);

  useEffect(() => {
    let cleanUpGetChatMessages;
    if (chat.chat_id === -1) {
      onNewMessageSent();
    }
    if (chat.chat_id !== -1) {
      cleanUpGetChatMessages = getChatMessages();
    }

    getMessageTyping();
    scrollToBottom();

    return () => {
      if (chat.chat_id !== -1) {
        cleanUpGetChatMessages && cleanUpGetChatMessages();
      }
    };
  }, [chat, sentNewMsg]);

  const onNewMessageSent = () => {
    socketContext.socket.on("newChat" + userId, (data) => {
      setChat((prevChat) => {
        // Save old chat_id before updating
        const oldChatId = prevChat.chat_id;

        // Update the chat object with new data
        const updatedChat = {
          ...prevChat,
          ...data, // This includes the new chat_id
          chat_name: prevChat.chat_name, // Preserve any necessary properties
        };

        // Update the chat ID in the openChats array
        updateChatId(oldChatId, data.chat_id);

        return updatedChat;
      });

      setSentNewMsg(true);
    });
  };

  useEffect(() => {
    if (chatMessagesRef.current !== 0) {
      if (isScrolledToBottom(messageContainerRef)) {
        scrollToBottom();
      }
    }
  }, [messageStatus]);

  const getMessageTyping = () => {
    const messageTypingListener = (data) => {
      if (data && data !== userId) {
        if (typingTimer.current) {
          clearTimeout(typingTimer.current);
        }

        setIsTyping(true);
        typingTimer.current = setTimeout(resetTyping, 1000);
      }
    };
    socketContext.socket.on(
      "messageTyping" + chat.chat_id,
      messageTypingListener
    );
  };

  const sendMessageTyping = () => {
    const body = {
      chat_id: chat.chat_id,
      user_id: userId,
    };
    socketContext.socket.emit("messageTyping", body);
  };

  const getChatMessages = () => {
    let args = { chat_id: chat.chat_id };

    const getChatMessagesListener = (data) => {
      setChatMessages(data);
    };

    const refreshChatMessagesListener = () => {
      socketContext.socket.emit("getChatMessages", args);
    };

    socketContext.socket.on(
      "chatMessages" + chat.chat_id,
      getChatMessagesListener
    );
    socketContext.socket.emit("getChatMessages", args);
    socketContext.socket.on(
      "refreshChatMessages" + chat.chat_id,
      refreshChatMessagesListener
    );

    return () => {
      socketContext.socket.off("getChatMessages", getChatMessagesListener);
      socketContext.socket.off(
        "chatMessages" + chat.chat_id,
        getChatMessagesListener
      );
      socketContext.socket.off(
        "refreshChatMessages" + chat.chat_id,
        refreshChatMessagesListener
      );
    };
  };

  const sendMessageReceipt = (messageId) => {
    const body = {
      chat_id: chat.chat_id,
      message_id: messageId,
      reader_id: userId,
    };
    socketContext.socket.emit("sendMessageReceipt", body);
  };

  const getMessageReceipts = (messageId) => {
    let args = { message_id: messageId };

    const getMessageReceiptsListener = (data) => {
      if (data && data.length) {
        if (data[0]) {
          setMessageStatus("read");
        }
      } else {
        setMessageStatus("sent");
      }
      setMessageReceipts(data);
    };

    const refreshMessageReceiptsListener = () => {
      socketContext.socket.emit("getMessageReceipts", args);
    };

    socketContext.socket.on(
      "messageReceipts" + messageId,
      getMessageReceiptsListener
    );
    socketContext.socket.emit("getMessageReceipts", args);
    socketContext.socket.on(
      "refreshMessageReceipts" + messageId,
      refreshMessageReceiptsListener
    );

    return () => {
      socketContext.socket.off(
        "getMessageReceipts",
        getMessageReceiptsListener
      );
      socketContext.socket.off(
        "messageReceipts" + messageId,
        getMessageReceiptsListener
      );
      socketContext.socket.off(
        "refreshMessageReceipts" + messageId,
        refreshMessageReceiptsListener
      );
    };
  };

  const toggleChat = (e) => {
    console.log(e.target);
    console.log(editRef.current);

    if (chat.is_group_chat) {
      if (e.target.contains(editRef.current) && e.target !== editRef.current) {
        if (window.innerWidth > 800) {
          if (chatIsOpen) {
            setChatIsOpen(false);
            setFocused(false);
          } else {
            setChatIsOpen(true);
          }
        }
      }
    } else {
      if (window.innerWidth > 800) {
        if (chatIsOpen) {
          setChatIsOpen(false);
          setFocused(false);
        } else {
          setChatIsOpen(true);
        }
      }
    }
  };

  const closeChat = () => {
    closeChatId(chat.chat_id); // Now uses the updated chat_id
    dispatch(closeOpenChat(chat.chat_id));
  };

  const sendMessage = (isGif, gifUrl) => {
    if (newMessage || isGif) {
      const newChatMessage = {
        message_id: -1,
        chat_id: chat.chat_id,
        sender_id: userId,
        message_content: gifUrl ? gifUrl : newMessage,
        is_gif: isGif,
      };
      setChatMessages([...chatMessages, newChatMessage]);
      if (chat.chat_id === -1) {
        const body = {
          message: gifUrl ? gifUrl : newMessage,
          user_id: userId,
          recipients: chat.user_id,
          is_gif: isGif,
        };
        socketContext.socket.emit("sendNewMessage", body);
      } else {
        const body = {
          chat_id: chat.chat_id,
          sender_id: userId,
          message: gifUrl ? gifUrl : newMessage,
          is_gif: isGif,
          participants: chat.participants,
        };
        socketContext.socket.emit("sendMessage", body);
      }
      if (chat.chat_id !== -1) {
        try {
          const participants = JSON.parse(chat.participants);
          participants.map((usr) => {
            if (usr !== userId) {
              let notificationTitle = chat.chat_name;
              let notificationDescription = "";
              if (isGif) {
                notificationDescription = "Σας έστειλε ένα gif";
              } else {
                notificationDescription = newMessage;
              }

              let args = {
                notification: {
                  title: notificationTitle,
                  body: notificationDescription,
                },
              };

              const notificationBody = {
                user_id: usr,
                notification_body: args,
                notification_type: "send-message",
              };

              socketContext.socket.emit("sendNotification", notificationBody);
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        let notificationTitle = chat.chat_name;
        let notificationDescription = "";
        if (isGif) {
          notificationDescription = "Σας έστειλε ένα gif";
        } else {
          notificationDescription = newMessage;
        }

        let args = {
          notification: {
            title: notificationTitle,
            body: notificationDescription,
          },
        };

        const notificationBody = {
          user_id: chat.user_id,
          notification_body: args,
          notification_type: "send-message",
        };

        socketContext.socket.emit("sendNotification", notificationBody);
      }
      setNewMessage("");
      setMessageStatus("");
    }
  };

  const populateMessages = () => {
    return chatMessages.map((message, index) => {
      const prevMessage = chatMessages[index - 1];
      const nextMessage = chatMessages[index + 1];
      const isLastMessage = index === chatMessages.length - 1;

      const showDate =
        prevMessage &&
        new Date(message.timestamp).getTime() -
          new Date(prevMessage.timestamp).getTime() >=
          86400000;

      // Checking if the next message is from a different user or there's a significant time gap
      const isNextMessageFromDifferentUser =
        !nextMessage || nextMessage.sender_id !== message.sender_id;
      const isNextMessageFarApart =
        nextMessage &&
        new Date(nextMessage.timestamp).getTime() -
          new Date(message.timestamp).getTime() >=
          60000;

      // Checking if the previous message is from a different user or there's a significant time gap
      const isPrevMessageFromDifferentUser =
        !prevMessage || prevMessage.sender_id !== message.sender_id;
      const isPrevMessageFarApart =
        prevMessage &&
        new Date(message.timestamp).getTime() -
          new Date(prevMessage.timestamp).getTime() >=
          60000;

      // Determining if a message is isolated
      const isIsolatedMessage =
        (isPrevMessageFromDifferentUser || isPrevMessageFarApart) &&
        (isNextMessageFromDifferentUser ||
          isNextMessageFarApart ||
          isLastMessage);

      // Determining the positioning of the message
      const isFirstMessage =
        (isPrevMessageFromDifferentUser || isPrevMessageFarApart) &&
        !isIsolatedMessage;
      const isMiddleMessage =
        !isIsolatedMessage &&
        !isFirstMessage &&
        !isLastMessage &&
        !isNextMessageFromDifferentUser &&
        !isNextMessageFarApart;
      const isLastMessageInSeries =
        (!isIsolatedMessage && isNextMessageFromDifferentUser) ||
        (isNextMessageFarApart && !isFirstMessage) ||
        (isLastMessage && !isIsolatedMessage);

      const showProfileImage =
        message.sender_id !== userId &&
        (isIsolatedMessage ||
          isLastMessage ||
          isNextMessageFromDifferentUser ||
          isNextMessageFarApart);

      const isLastAndSender = isLastMessage && message.sender_id == userId;

      return (
        <div
          key={"message" + message.message_id}
          className={`message ${message.sender_id === userId ? "sender" : ""} ${
            showDate ? " with-date " : ""
          } ${isFirstMessage || isIsolatedMessage ? "first" : ""} ${
            isLastAndSender && messageStatus !== "" ? " isLastAndSender" : ""
          }`}
        >
          {showDate ? (
            <span className="message__date">
              {formatTimestamp(message.timestamp)}
            </span>
          ) : (
            ""
          )}
          {showProfileImage && (
            <img
              className="bubble__profile"
              src={
                message.profile_picture !== "" &&
                message.profile_picture !== null
                  ? message.profile_picture
                  : "/resources/student.png"
              }
              alt="profile"
            />
          )}
          <div className="bubble__preview-time">
            {formatTimestamp(message.timestamp)}
          </div>
          <div
            className={`bubble ${message.is_gif ? "has-gif" : ""} ${
              showProfileImage ? "has-image" : ""
            } ${isFirstMessage ? "first" : ""} ${
              isMiddleMessage ? "middle" : ""
            } ${isLastMessageInSeries ? "last" : ""}`}
          >
            {message.is_gif ? (
              <img
                className="bubble__gif"
                src={message.message_content}
                alt="gif"
              />
            ) : (
              <span className="bubble__content">{message.message_content}</span>
            )}
            {isLastAndSender && (
              <div className="message__status">
                {messageStatus === "sent" ? (
                  <div className="message__status-sent">
                    <span>Στάλθηκε</span>
                  </div>
                ) : (
                  ""
                )}
                {messageStatus === "read" ? (
                  <div className="message__status-read">
                    <span>Διαβάστηκε</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="chat-item"
      tabIndex="0"
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {isEdit ? (
        <div
          className="modal-background show"
          onClick={() => setIsEdit(false)}
        ></div>
      ) : (
        ""
      )}
      {isEdit ? (
        <ChatEdit chat={chat} chatImage={chatImage} setIsOpen={setIsEdit} />
      ) : (
        ""
      )}
      <div
        className={
          "chat-item__header " + (!focused && !readLastMsg ? "not-read" : "")
        }
        onClick={(e) => toggleChat(e)}
      >
        <div className="info">
          <div className="info__back" onClick={() => navigateBack()}>
            <BiArrowBack
              size={"25px"}
              color={"#7e7e7e"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="info__img">
            <img src={chatImage} alt="profile" />
          </div>
          <span className="info__name">
            {chat.is_group_chat && !chat.chat_name ? "Ομαδική Συνομιλία" : ""}
            {chat.chat_name ? chat.chat_name : ""}
          </span>
        </div>
        <div className="actions">
          {chat.is_group_chat ? (
            <div
              className="actions__edit"
              ref={editRef}
              onClick={() => setIsEdit(true)}
            >
              <BiPencil
                size={"25px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />
            </div>
          ) : (
            ""
          )}
          <div className="actions__close" onClick={() => closeChat()}>
            <BiX
              size={"32px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
            />
          </div>
        </div>
      </div>
      <div className={"chat-item__history " + (!chatIsOpen ? "close" : "")}>
        <div className="content" ref={messageContainerRef}>
          {populateMessages()}
          {isTyping ? (
            <div className="message-typing">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="create">
          <textarea
            ref={newMessageRef}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevents the default action of inserting a newline
                sendMessage(false);
              }
            }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className={"input " + (newMessage ? "full" : "")}
            placeholder="Μήνυμα..."
          ></textarea>
          {!newMessage ? <GifBox sendMessage={sendMessage} /> : ""}
          <div className="create__send" onClick={() => sendMessage()}>
            <BiSend
              size={"30px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatItem;
