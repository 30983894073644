import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import "./curriculum-card.scss";
import { BiArrowToBottom, BiLockAlt, BiSearch, BiUpload } from "react-icons/bi";
import { SocketContext } from "../../../../app/socket";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Compress from "compress.js";
import ExamThumbnail from "../../../../components/ExamThumbnail/exam-thumbnail";
import { nanoid } from "nanoid";
import { fileUtils } from "../../../../utils/fileUtils";
import { calls } from "../../../../config/db_config";
import axios from "axios";
import { tokenUtils } from "../../../../utils/token-utils";
import { useSelector } from "react-redux";
import StudentAnswerItem from "../../studentAnswerItem";
import { iconStyle } from "../../../../utils/generalUtils";
import ImageSlider from "../../../../components/ImageSlider/imageSlider";
import CurriculumAnswers from "../CurriculumAnswers/curriculumAnswers";
import LiveWorksheetEmbed from "./LiveWorksheetEmbed";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

function CurriculumCard({
  curriculum,
  departmentNames,
  itemClassName,
  setCurriculumToEdit,
}) {
  console.log(curriculum);
  const hasCameraAccess = useSelector((state) => state.profile.hasCameraAccess);
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef(null);
  const previewRef = useRef(null);
  const [style, setStyle] = useState({});
  const [allFiles, setAllFiles] = useState([]);
  const envVars = useSelector((state) => state.profile.environmentVars);

  const index = 0;
  const [submitFiles, setSubmitFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const compress = new Compress();
  const [answerText, setAnswerText] = useState("");

  const [curriculumAnswers, setCurriculumAnswers] = useState([]);

  const [imageIsOpen, setImageIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState("");

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    return getUserData();
  }, [curriculum.id]);

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(80);
  const [modalLeft, setModalLeft] = useState(0.1);
  const [modalTop, setModalTop] = useState(0.075);

  useEffect(() => {
    console.log(curriculum);
    if (curriculum.files) {
      try {
        const temp = [];
        const tempFiles = JSON.parse(curriculum.files);
        tempFiles.map((file) => {
          file.map((fileItem) => {
            temp.push(fileItem);
          });
        });
        setAllFiles(temp);
      } catch (e) {
        console.log(e);
      }
    }
    if (window.innerWidth < 800) {
      setModalHeight(93);
      setModalWidth(90);
      setModalTop(0.05);
      setModalLeft(0.03);
    } else {
      setModalHeight(90);
      setModalWidth(80);
      setModalTop(0.05);
      setModalLeft(0.1);
    }
    const cleanUpGetCurriculumAnswers = getCurriculumAnswers();
    return () => {
      cleanUpGetCurriculumAnswers();
    };
  }, []);

  const openCard = (e) => {
    const isCardAction = e.target.closest(".card-action");
    const isFiles = e.target.closest(".curriculum-card__files-item");
    if (!isCardAction && !isExpanded && !isFiles) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;
      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
        height: "auto",
        transform: "translate(-50%, -50%)",
        position: "fixed",
        top: "50%",
        left: "50%",
      };
      setStyle(newPositionStyle);
    } else {
      setStyle({});
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    if (imageIsOpen) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
    } else {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [imageIsOpen]);

  const getUserData = () => {
    const getUsersListener = (data) => {
      if (data.length > 0) {
        setUserData(data[0]);
      } else {
        setUserData([]);
      }
    };

    socketContext.socket.on(
      "allUsersWithParams" + curriculum.created_by,
      getUsersListener
    );

    const args = { user_id: curriculum.created_by };
    socketContext.socket.emit("getAllUsersWithParams", args);

    const refreshAllUsersWithParamsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshAllUsersWithParamsListener
    );

    return () => {
      socketContext.socket.off(
        "allUsersWithParams" + curriculum.created_by,
        getUsersListener
      );
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshAllUsersWithParamsListener
      );
    };
  };

  const openFile = (image) => {
    setImageIsOpen(true);
    setActiveImage(image);
  };

  const populateFiles = () => {
    try {
      const tempFiles = JSON.parse(curriculum.files);
      return tempFiles.map((images, index) => {
        return images.map((image, imageIndex) => {
          const lastIndex = image.lastIndexOf("/");
          const imageName = image.substring(lastIndex + 1);
          const fileExtension = image.substring(image.lastIndexOf(".") + 1);

          return (
            <div
              key={"asset__item" + imageIndex}
              className={
                "curriculum-card__files-item " +
                (isExpanded ? " expanded " : "") +
                (fileExtension !== "pdf" && fileExtension != "PDF"
                  ? "img"
                  : "pdf") +
                (fileExtension == "mp3" || fileExtension == "wma"
                  ? " audio"
                  : "") +
                (fileExtension == "ppt" || fileExtension == "pptx"
                  ? " ppt"
                  : "")
              }
              onClick={() => openFile(image)}
              title={imageName}
            >
              <div className="curriculum-card__files-item-overlay">
                <BiSearch
                  size={"30px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                />
              </div>
              {image.substring(image.lastIndexOf(".") + 1) == "pdf" ||
              image.substring(image.lastIndexOf(".") + 1) == "PDF" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/pdf-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) == "ppt" ||
              image.substring(image.lastIndexOf(".") + 1) == "pptx" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/ppt-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) == "mp3" ||
              image.substring(image.lastIndexOf(".") + 1) == "MP3" ||
              image.substring(image.lastIndexOf(".") + 1) == "wma" ||
              image.substring(image.lastIndexOf(".") + 1) == "WMA" ? (
                <img
                  className="card__info-submitted-img"
                  src="../../../resources/icons/audio-icon.png"
                />
              ) : (
                ""
              )}
              {image.substring(image.lastIndexOf(".") + 1) !== "pdf" &&
              image.substring(image.lastIndexOf(".") + 1) !== "PDF" &&
              image.substring(image.lastIndexOf(".") + 1) !== "mp3" &&
              image.substring(image.lastIndexOf(".") + 1) !== "wma" ? (
                <img className="card__info-submitted-img img" src={image} />
              ) : (
                ""
              )}
            </div>
          );
        });
      });
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const getCurriculumAnswers = () => {
    let args = {
      curriculum_id: curriculum.id,
    };
    if (profile.user_type == "student") {
      args.student_id = profile.user_id;
    }

    const getCurriculumAnswersListener = (data) => {
      setCurriculumAnswers(data);
    };

    const refreshCurriculumAnswersListener = () => {
      socketContext.socket.emit("getCurriculumAnswers", args);
    };

    socketContext.socket.on(
      "curriculumAnswers" + curriculum.id,
      getCurriculumAnswersListener
    );
    socketContext.socket.emit("getCurriculumAnswers", args);
    socketContext.socket.on(
      "refreshCurriculumAnswers" + curriculum.id,
      refreshCurriculumAnswersListener
    );

    return () => {
      socketContext.socket.off(
        "getCurriculumAnswers",
        getCurriculumAnswersListener
      );
      socketContext.socket.off(
        "curriculumAnswers" + curriculum.id,
        getCurriculumAnswersListener
      );
      socketContext.socket.off(
        "refreshCurriculumAnswers" + curriculum.id,
        refreshCurriculumAnswersListener
      );
    };
  };

  const imageChange = async (event) => {
    let fileList = Array.from(event.target.files);

    const imageList = fileList.filter(
      (file) => file.type !== "application/pdf"
    );
    const pdfList = fileList.filter((file) => file.type === "application/pdf");

    let tempThumbnailList = [];

    let updatedFileList = submitFiles;
    updatedFileList[index] = [];

    await compress
      .compress(imageList, {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, // See the rotation section below
      })
      .then((data) => {
        data.forEach(function (singleData) {
          const base64 = singleData.data;
          const name = singleData.alt;
          const imgExt = singleData.ext;

          const blob = Compress.convertBase64ToFile(base64, imgExt);

          // let mFile = dataURLToFile(base64, name);
          let file = new File([blob], name);
          updatedFileList[index].push(file);

          let baseData = "data:" + imgExt + ";base64," + base64;
          tempThumbnailList.push(baseData);
        });
      });
    // set thumbnails (base64 inside)
    setThumbnails(tempThumbnailList);

    updatedFileList.push(pdfList);
    // set files (file types inside)
    setSubmitFiles(updatedFileList);
  };

  const populateThumbnails = () => {
    return thumbnails.map((image, i) => {
      return (
        <ExamThumbnail
          key={i}
          index={i}
          removeThumbnail={removeThumbnail}
          image={image}
        />
      );
    });
  };

  const removeThumbnail = (i) => {
    let updatedFileList = submitFiles;
    let tempThumbnails = thumbnails;

    if (thumbnails.length >= 1) {
      updatedFileList[index].splice(i, 1);
      tempThumbnails.splice(i, 1);
    } else {
      updatedFileList[index] = [];
      tempThumbnails = [];
    }

    setThumbnails(Array.from(tempThumbnails));
    setSubmitFiles(updatedFileList);
  };

  const submitCurriculumAnswers = () => {
    let submissionLinks = [];
    const formData = new FormData();
    let i = 0;
    if (submitFiles.length) {
      submitFiles.forEach((file) => {
        let j = 0;
        submissionLinks.push([]);
        file.forEach((image) => {
          let blob = image.slice(0, image.size);
          let name = `curriculum-answer-${i}-${j++}-${nanoid()}.${fileUtils.getFileExtension(
            image.name
          )}`;
          let newFile = new File([blob], name, { type: image.type });

          let link =
            calls.endpoint +
            `/resources/${envVars.DB_SCHOOL_NAME}/curriculum-answers/` +
            name;
          submissionLinks[i].push(link);

          formData.append("arrayOfFilesName", newFile);
        });
        i++;
      });
      try {
        axios
          .post(calls.submitCurriculumAnswersImages, formData, {
            headers: { Authorization: tokenUtils.getBearerToken() },
          })
          .then((response) => {
            if (response.data) {
              const body = {
                submissionLinks: submissionLinks,
                curriculum_id: curriculum.id,
                answer_text: answerText,
              };

              socketContext.socket.emit("uploadCurriculumAnswers", body);
              setSubmitFiles([]);
              setThumbnails([]);
            }
          });
      } catch (ex) {
        console.log(ex);
      }
    } else {
      const body = {
        submissionLinks: submissionLinks,
        curriculum_id: curriculum.id,
        answer_text: answerText,
      };

      socketContext.socket.emit("uploadCurriculumAnswers", body);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteCurriculum = () => {
    const body = {
      curriculum_id: curriculum.id,
    };
    socketContext.socket.emit("deleteCurriculum", body);
    setShowDeleteModal(false);
    setIsExpanded(false);
  };

  const isRunningInWebView = () => {
    return window.ReactNativeWebView != null;
  };

  const downloadFiles = async () => {
    const handleFetchError = (url, error) => {
      console.error(`Error while fetching ${url}: ${error.message}`);
      throw new Error(`Error while fetching ${url}: ${error.message}`);
    };

    const fetchFiles = async (urls) => {
      return Promise.all(
        urls.map(async (url) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              handleFetchError(url, new Error(response.statusText));
            }
            const blob = await response.blob();
            const fileName = url.split("/").pop() || `file${urls.indexOf(url)}`;
            return { fileName, blob };
          } catch (error) {
            handleFetchError(url, error);
          }
        })
      );
    };

    const addFilesToZip = (zip, files) => {
      files.forEach(({ fileName, blob }) => {
        zip.file(fileName, blob);
      });
    };

    const createZipBlob = async (files) => {
      const zip = new JSZip();
      addFilesToZip(zip, files);
      return await zip.generateAsync({ type: "blob" });
    };

    try {
      if (isRunningInWebView()) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "START_LOADER" })
        );

        const images = JSON.parse(curriculum.files);
        const filesArray = [...images[0], ...(images[1] || [])];

        const files = await fetchFiles(filesArray);
        const zipBlob = await createZipBlob(files);

        const zipFileName = `${curriculum.title}-${curriculum.id}.zip`;
        const encodedFileName = encodeURIComponent(zipFileName);
        const config = {
          headers: {
            Authorization: tokenUtils.getBearerToken(),
          },
        };
        const formData = new FormData();
        formData.append("file", zipBlob, encodedFileName);

        const response = await axios.post(calls.tempZip, formData, config);
        if (response.data && response.data.path) {
          const contentType = response.headers["content-type"];
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "DOWNLOAD_FILE",
              url: response.data.path,
              fileName: zipFileName,
              contentType: contentType,
            })
          );
        }
      } else {
        const images = JSON.parse(curriculum.files);
        const filesArray = [...images[0], ...(images[1] || [])];

        const files = await fetchFiles(filesArray);
        const zipBlob = await createZipBlob(files);

        const zipFileName = `${curriculum.title}-${curriculum.id}.zip`;
        saveAs(zipBlob, zipFileName);
      }
    } catch (error) {
      console.error(error);
      if (isRunningInWebView()) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "STOP_LOADER" })
        );
      }
    }
  };

  const editClick = () => {
    console.log(" editing curriculum ");
    setIsExpanded(false);
    setTimeout(() => {
      setCurriculumToEdit(curriculum);
    }, [400]);
  };

  const embedHtml = `
<div id="liveworksheet59098" style="width:100%">
  <span id="lwslink59098">
    <a href="https://www.liveworksheets.com/w/en/english-second-language-esl/59098">Present Simple</a>, an interactive worksheet by <a href="https://www.liveworksheets.com/u/annamisztela">Anna Misztela</a>
    <br><a href="https://www.liveworksheets.com">live<b>worksheets.com</b></a>
  </span>
</div>
<script src="https://www.liveworksheets.com/embed/embed.js?v=1"></script>
<script>
  loadliveworksheet(59098,'hrhk',1633,'www','new');
</script>
`;

  return (
    <div
      ref={containerRef}
      className={
        "curriculum-card " + (isExpanded ? " is-expanded " : " is-collapsed  ")
      }
    >
      <div className={"delete-modal " + (showDeleteModal ? "show" : "")}>
        <div className="delete-container">
          <span className="title">
            Είστε σίγουροι ότι θέλετε να διαγράψετε την επιλεγμένη ύλη;
          </span>
          <div className="controls">
            <button className="cta" onClick={() => setShowDeleteModal(false)}>
              Ακύρωση
            </button>
            <button className="cta cta-red" onClick={() => deleteCurriculum()}>
              Διαγραφή
            </button>
          </div>
        </div>
      </div>
      {imageIsOpen ? (
        <div
          onClick={() => setImageIsOpen(false)}
          className={"modal-background show z-index"}
        ></div>
      ) : (
        ""
      )}
      {imageIsOpen ? (
        <ImageSlider
          assets={allFiles}
          activeAsset={activeImage}
          disableDownload={curriculum.disable_download}
        />
      ) : (
        ""
      )}
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        initial={false}
        className={
          "curriculum-card__content " +
          (isExpanded ? "is-expanded" : "is-collapsed") +
          (curriculum.curriculum_id == -1 ? " loading-item bottom" : "") +
          (curriculum.live_work_link ? " live-work" : "")
        }
        onClick={(e) => openCard(e)}
        style={style}
      >
        <div className={"curriculum-card__header "}>
          <div className="curriculum-card__header-profile">
            <div className="profile__image">
              {curriculum.curriculum_id != -1 ? (
                <img
                  alt="profile student professor"
                  src={
                    userData.profile_picture
                      ? userData.profile_picture
                      : "resources/student.png"
                  }
                />
              ) : (
                <img
                  alt="profile student professor"
                  src={
                    profile.profile_picture
                      ? profile.profile_picture
                      : "resources/student.png"
                  }
                />
              )}
            </div>
            <div className="profile__details">
              <div className="info">
                {curriculum.curriculum_id != -1 ? (
                  <div className="name">
                    <span>{userData.first_name}</span>
                    <span>{userData.last_name}</span>
                  </div>
                ) : (
                  <div className="name">
                    <span>{profile.first_name}</span>
                    <span>{profile.last_name}</span>
                  </div>
                )}
                {curriculum.curriculum_id != -1 ? (
                  <span className="date">
                    {formatDate(curriculum.created_at)}
                  </span>
                ) : (
                  <span className="date">{formatDate(new Date())}</span>
                )}
                {curriculum.is_locked ? (
                  <div className="profile__details-lock">
                    <BiLockAlt
                      size={"20px"}
                      color={"#ccc"}
                      style={iconStyle("transparent")}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <span className="details">
                <span>{curriculum.class_name}</span>
                <span>-</span>
                <span>{curriculum.chapter_names}</span>
                <span>-</span>
                <span>{curriculum.department_names}</span>
              </span>
            </div>
          </div>
        </div>
        <span className="curriculum-card__title">{curriculum.title}</span>
        {isExpanded && curriculum.live_work_link ? (
          <div className="curriculum-livework">
            <div className="curriculum-livework__loader">
              <div className="loader-container">
                <div class="containLoader">
                  <div class="circleGroup circle-1"></div>
                  <div class="circleGroup circle-2"></div>
                  <div class="circleGroup circle-3"></div>
                  <div class="circleGroup circle-4"></div>
                  <div class="circleGroup circle-5"></div>
                  <div class="circleGroup circle-6"></div>
                  <div class="circleGroup circle-7"></div>
                  <div class="circleGroup circle-8"></div>

                  <div class="innerText">
                    <p>Φόρτωση...</p>
                  </div>
                </div>
              </div>
            </div>
            <LiveWorksheetEmbed
              key={"liveworksheet curri" + curriculum.id}
              embedHtml={curriculum.live_work_link}
            />
          </div>
        ) : (
          ""
        )}
        {!isExpanded && curriculum.live_work_link ? (
          <div className="curriculum-card__live-work-preview">
            <span>Live Work</span>
          </div>
        ) : (
          ""
        )}
        {curriculum.files ? (
          <div className="curriculum-card__files">{populateFiles()}</div>
        ) : (
          ""
        )}
        {profile.user_type == "student" &&
        curriculum.is_exercise &&
        !isExpanded ? (
          <div className="curriculum-card__chip">
            <span>
              {curriculumAnswers.length ? "Υποβλήθηκε" : "Δυνατότητα Υποβολής"}
            </span>
            {curriculumAnswers.length ? <div></div> : ""}
          </div>
        ) : (
          ""
        )}
        {(profile.user_type == "professor" || profile.user_type == "admin") &&
        curriculumAnswers.length ? (
          <div className="curriculum-card__chip">
            <span>
              {" "}
              {curriculumAnswers.length}{" "}
              {curriculumAnswers.length > 1 ? " Υποβολές" : " Υποβολή"}
            </span>
          </div>
        ) : (
          ""
        )}
        {curriculum.files &&
        (profile.user_type != "student" || !curriculum.disable_download) ? (
          <div className="curriculum-card__download">
            <button className="cta card-action" onClick={() => downloadFiles()}>
              <span className="card-action">Λήψη όλων</span>
              <BiArrowToBottom
                size={"20px"}
                color={"#6225E6"}
                style={iconStyle("transparent")}
              />
            </button>
          </div>
        ) : (
          ""
        )}
        {isExpanded && curriculum.is_exercise ? (
          <div className="curriculum-card__submissions">
            <div className="curriculum-card__submissions-title">
              <span>
                {profile.user_type == "student"
                  ? curriculumAnswers.length
                    ? "Απαντήσεις"
                    : "Υποβολή Απαντήσεων"
                  : ""}
                {profile.user_type != "student" && profile.user_type != "parent"
                  ? "Απαντήσεις Μαθητών"
                  : ""}
              </span>
            </div>
            {profile.user_type == "student" && !curriculumAnswers.length ? (
              <div className="curriculum-card__submissions-upload">
                <div className="upload-files">
                  <span className="label">
                    Ανεβάστε αρχεία με τις απαντήσεις σας
                  </span>
                  <div className="upload-files__button">
                    <BiUpload
                      size={"60px"}
                      color={"#ccc"}
                      style={iconStyle("transparent")}
                    />
                    {hasCameraAccess !== 0 && (
                      <input
                        className="upload-files__button-input"
                        type="file"
                        accept=".pdf, .jpeg, .jpg, .png"
                        name="myImage"
                        onChange={imageChange}
                        multiple
                      />
                    )}
                  </div>
                  {!hasCameraAccess && (
                    <span className="upload-files__error">
                      Ενεργοποιήστε τα δικαιώματα κάμερας για να έχετε πρόσβαση
                      στην κάμερα και στα αρχεία σας
                    </span>
                  )}
                  <div className="upload-files__thumbnails">
                    {populateThumbnails()}
                  </div>
                </div>
                <div className="upload-text">
                  <span className="label">
                    Ή πληκτρολογήστε τις απαντήσεις σας εδω.
                  </span>
                  <textarea
                    value={answerText}
                    onChange={(e) => setAnswerText(e.target.value)}
                    placeholder="Εισάγεται την απάντηση σας εδώ..."
                  ></textarea>
                </div>
                <div className="upload-submit">
                  <button
                    className="cta cta-fill"
                    onClick={() => submitCurriculumAnswers()}
                  >
                    Υποβολή
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {curriculumAnswers.length && isExpanded ? (
          <CurriculumAnswers curriculumAnswers={curriculumAnswers} />
        ) : (
          ""
        )}
        {isExpanded &&
        profile.user_type != "student" &&
        profile.user_type != "parent" ? (
          <div className="curriculum-card__actions">
            <button
              className="cta cta-red"
              onClick={() => setShowDeleteModal(true)}
            >
              Διαγραφή
            </button>
            <button className="cta cta-fill" onClick={() => editClick()}>
              Επεξεργασία
            </button>
          </div>
        ) : (
          ""
        )}
      </motion.div>
    </div>
  );
}

export default CurriculumCard;
