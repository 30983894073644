import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../../app/socket";
import { motion } from "framer-motion";
import "./student-comments.scss";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../../../utils/generalUtils";
import CommentItem from "./commentItem";

function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function StudentComments({ event, students, selectedDate }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [comments, setComments] = useState([]);

  const [lectureStudentProgress, setLectureStudentProgress] = useState([]);

  useEffect(() => {
    const cleanUpGetEventStudentComments = getStudentComments();
    const cleanUpGetLectureProgress = getLectureProgress();
    return () => {
      cleanUpGetLectureProgress();
      cleanUpGetEventStudentComments();
    };
  }, []);

  const getLectureProgress = () => {
    let args = { event_id: event.event_id, created_at: selectedDate };
    const getLectureStudentProgressListener = (data) => {
      // console.log(" lecture student progress ");
      // console.log(data);
      setLectureStudentProgress(data);
    };
    const refreshLectureStudentProgressListener = () => {
      socketContext.socket.emit("getLectureStudentProgress", args);
    };
    socketContext.socket.on(
      "lectureStudentProgress" + event.event_id,
      getLectureStudentProgressListener
    );
    socketContext.socket.emit("getLectureStudentProgress", args);
    socketContext.socket.on(
      "refreshLectureStudentProgress" + event.event_id,
      refreshLectureStudentProgressListener
    );
    return () => {
      socketContext.socket.off(
        "getLectureStudentProgress",
        getLectureStudentProgressListener
      );
      socketContext.socket.off(
        "lectureStudentProgress" + event.event_id,
        getLectureStudentProgressListener
      );
      socketContext.socket.off(
        "refreshLectureStudentProgress" + event.event_id,
        refreshLectureStudentProgressListener
      );
    };
  };

  const getStudentComments = () => {
    let args = { event_id: event.event_id, created_at: selectedDate };
    const getCommentsListener = (data) => {
      setComments(data);
    };
    const refreshCommentsListener = () => {
      socketContext.socket.emit("getEventStudentComments", args);
    };
    socketContext.socket.on(
      "eventStudentComments" + event.event_id,
      getCommentsListener
    );
    socketContext.socket.emit("getEventStudentComments", args);
    socketContext.socket.on(
      "refreshEventStudentComments" + event.event_id,
      refreshCommentsListener
    );
    return () => {
      socketContext.socket.off("getEventStudentComments", getCommentsListener);
      socketContext.socket.off(
        "eventStudentComments" + event.event_id,
        getCommentsListener
      );
      socketContext.socket.off(
        "refreshEventStudentComments" + event.event_id,
        refreshCommentsListener
      );
    };
  };

  const populateStudentComments = () => {
    return students.map((student, index) => {
      return (
        <CommentItem
          event={event}
          student={student}
          selectedDate={selectedDate}
          comment={comments.find(
            (comment) =>
              comment.event_id == event.event_id &&
              isSameDay(new Date(comment.created_at), new Date(selectedDate)) &&
              comment.to_user_id == student.user_id
          )}
          lectureStudentProgress={
            lectureStudentProgress.filter(
              (prog) => prog.user_id == student.user_id
            )
              ? lectureStudentProgress.filter(
                  (prog) => prog.user_id == student.user_id
                )
              : ""
          }
        />
      );
    });
  };

  return (
    <div className="student-comments">
      <div className="student-comments__title">Σχόλια επίδοσης μαθητών</div>
      <div className="student-comments__list">{populateStudentComments()}</div>
    </div>
  );
}

export default StudentComments;
