import React, { useState, useEffect, useContext } from "react";
import { motion, usePresence } from "framer-motion";
import { SocketContext } from "../../app/socket";
import { CircularProgressbar } from "react-circular-progressbar";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import NumberInput from "../NumberInput/numberInput";
import { useSelector } from "react-redux";

function RecordStudent({
  student,
  examId,
  questionsNum,
  maxRank,
  detailedRank,
  selectedDate,
  isWeekly,
}) {
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const socketContext = useContext(SocketContext);

  const [detailedRankNew, setDetailedRankNew] = useState(detailedRank);

  const [commentOpen, setCommentOpen] = useState(false);

  useEffect(() => {
    if (!detailedRank) {
      let temp = [];
      if (questionsNum) {
        for (let i = 0; i < questionsNum; i++) {
          temp.push(100 / questionsNum);
        }
        setDetailedRankNew(temp);
      } else {
        temp = [maxRank];
        setDetailedRankNew(temp);
      }
    } else {
      let parsedTemp = [];
      try {
        parsedTemp = JSON.parse(detailedRank);
        let temp = [];
        parsedTemp.map((item) => {
          if (item > 0) {
            temp.push(item);
          } else {
            temp.push(100 / questionsNum);
          }
          setDetailedRankNew(temp);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const examSize = questionsNum;

  const [isPresent, safeToRemove] = usePresence();
  const transition = {
    type: "spring",
    stiffness: 500,
    damping: 70,
    mass: 5,
    duration: 2,
  };
  const animations = {
    layout: true,
    initial: "in",
    style: {
      position: isPresent ? "relative" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scale: 1, opacity: 1 },
      out: { scale: 0, opacity: 0, zIndex: -1 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  const [detailedScore, setDetailedScore] = useState(Array(examSize).fill(0));

  const [score, setScore] = useState(0);
  const [text, setText] = useState("");

  const submitScore = () => {
    const body = {
      exam_id: examId,
      user_id: student.user_id,
      result_text: text,
      detailed_score: JSON.stringify(detailedScore),
      score: score,
      event_date: isWeekly && selectedDate ? selectedDate : "",
    };
    setScore(0);
    setText("");
    socketContext.socket.emit("markExam", body);
  };

  const submitNoScore = () => {
    const body = {
      exam_id: examId,
      user_id: student.user_id,
      result_text: text,
      not_scored: true,
    };
    setScore(0);
    setText("");
    socketContext.socket.emit("markExam", body);
  };

  const submitAbsense = () => {
    const body = {
      exam_id: examId,
      user_id: student.user_id,
      result_text: text,
      no_show: true,
    };
    setScore(0);
    setText("");
    socketContext.socket.emit("markExam", body);
  };

  useEffect(() => {
    const totalScore = detailedScore.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue),
      0
    );
    setScore(totalScore);
  }, [detailedScore]);

  const updateDetailedScore = (index, value) => {
    const temp = detailedScore;

    if (value <= parseInt(detailedRankNew[index])) {
      temp[index] = value;
      setDetailedScore([...temp]);
    } else {
      console.log(" too big ");
    }

    // setDetailedScore([...temp]);
  };

  const getThemaMax = (index) => {
    console.log(detailedRank);
    let temp = 0;
    try {
      temp = JSON.parse(detailedRank)[index];
    } catch (e) {
      console.log(e);
    }
    return temp;
  };

  return (
    <motion.div
      {...animations}
      className={"users__item " + (commentOpen ? "comment-open" : "")}
    >
      <div className="users__item-header">
        <div className="users__item-header-personal">
          <div className="image">
            <img
              alt=""
              src={
                student.profile_picture
                  ? student.profile_picture
                  : "resources/student.png"
              }
            />
          </div>
          <div className="details">
            <span className="details__name">
              {student.first_name}
              <span className="space"></span>
              {student.last_name}
            </span>
            <span className="details__department">
              {student.department_name}
            </span>
          </div>
        </div>
        <div className="users__item-header-score">
          {/* <span>Συνολικός Βαθμός</span> */}
          <CircularProgressbar
            pathColor="{red}"
            strokeWidth={9}
            className={"users__item-input-total-circle"}
            value={(100 / maxRank) * score}
            duration={1.4}
            text={score + "/" + maxRank}
          />
        </div>
      </div>
      <div className="users__item-content">
        <div className="users__item-content-scoring">
          {detailedScore.map((score, index) => {
            return (
              <div key={"score-item " + index} className="score-item">
                <span>Θέμα {index + 1}ο</span>
                <div className="score-item__input">
                  <BiChevronDown
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("#d6d6d6")}
                    onClick={() =>
                      updateDetailedScore(index, detailedScore[index] - 1)
                    }
                  />
                  <input
                    type="number"
                    value={detailedScore[index]}
                    onChange={(e) => updateDetailedScore(index, e.target.value)}
                  />
                  <BiChevronUp
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("#d6d6d6")}
                    onClick={() =>
                      updateDetailedScore(index, detailedScore[index] + 1)
                    }
                  />
                </div>
                <div className="score-item__max">
                  <span>Μεγ. Βαθμός: {getThemaMax(index)}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="users__item-content-final">
          <div className="score-comments">
            {/* <span>Σχόλια Διαγωνίσματος</span> */}
            {commentOpen ? (
              <div className="score-actions__comments">
                <textarea
                  placeholder="Εισάγεται σχόλια για την γενική επίδοση στο διαγώνισμα..."
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="score-actions">
            {/* {getGeneralSettingValue(
              generalSettings,
              "professors_view_all_events"
            ) ? (
              <span
                onClick={() => setCommentOpen(!commentOpen)}
                className="score-actions__add"
              >
                {!commentOpen ? "Προσθήκη σχολίου" : "Ακύρωση σχολίου"}
              </span>
            ) : (
              ""
            )} */}
            <span
              onClick={() => setCommentOpen(!commentOpen)}
              className="score-actions__add"
            >
              {!commentOpen ? "Προσθήκη σχολίου" : "Ακύρωση σχολίου"}
            </span>

            {!score || score <= 0 ? (
              <div className="score-actions__empty">
                <button className="cta" onClick={() => submitNoScore()}>
                  {window.location.hostname.includes("simmetria") ||
                  window.location.hostname.includes("localhost")
                    ? "Ανεπαρκές"
                    : "Αβαθμολόγητο"}
                </button>
                <button className="cta" onClick={() => submitAbsense()}>
                  Δεν προσήλθε
                </button>
              </div>
            ) : (
              <button
                className="cta cta-fill cta__save"
                onClick={() => submitScore()}
              >
                Βαθμολόγηση
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default RecordStudent;
