import "./timologio.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { BiEdit, BiTrash, BiCheck, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import AddTimologio from "./addTimologio";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Timologio() {
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const [timologio, setTimologio] = useState([]);

  useEffect(() => {
    const cleanUpGetTempTimologio = getTempTimologio();
    return () => {
      cleanUpGetTempTimologio();
    };
  }, []);

  const getTempTimologio = () => {
    let args = {};

    const getTimologioListener = (data) => {
      console.log(" -- -- -temp -----");
      console.log(data);
      setTimologio(data);
    };

    const refreshTimologioListener = () => {
      socketContext.socket.emit("getTempInvoices", args);
    };

    socketContext.socket.on("tempInvoices", getTimologioListener);
    socketContext.socket.emit("getTempInvoices", args);
    socketContext.socket.on("refreshTempInvoice", refreshTimologioListener);

    return () => {
      socketContext.socket.off("getTempInvoices", getTimologioListener);
      socketContext.socket.off("tempInvoices", getTimologioListener);
      socketContext.socket.off("refreshTempInvoice", refreshTimologioListener);
    };
  };

  // const populateTimologio = () => {
  //   return timologio.map((tim, index) => {
  //     return <div key={"timologio-item" + index} className="timologio__list-item">

  //     </div>
  //   })
  // }

  return (
    <div className={"section timologio-section"}>
      <span className="section__title">Τιμολόγιο</span>
      <AddTimologio isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <div className="timologio__list">{/* {populateTimologio()} */}</div>
    </div>
  );
}

export default Timologio;
