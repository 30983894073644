import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiAnalyse,
  BiBookOpen,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
  BiHome,
  BiSync,
  BiTime,
  BiUser,
  BiX,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { iconStyle } from "../../../../utils/generalUtils";
import "./professor-event-card.scss";
import ExamRecords from "../../../ExamCard/examRecords";
import { useSelector } from "react-redux";
import OnlineExamPreview from "./onlineExamPreview";
import DismissalUserItem from "../../../Calendar/dismissalUserItem";
import ChaptersTimeline from "../../ChaptersTimeline/chaptersTimeline";
import StudentComments from "./StudentComments/studentComments";
import { getGeneralSettingValue } from "../../../../utils/generalUtils";
import EventComment from "../../EventComment/eventComment";

function ProfessorEventCard({
  event,
  closeCard,
  selectedDate,
  dismissalInitActive,
  showIframe,
  setShowIframe,
}) {
  const socketContext = useContext(SocketContext);

  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [students, setStudents] = useState([]);
  const [dismissals, setDismissals] = useState([]);

  useEffect(() => {
    console.log("event");
    console.log(event);
    if (event.type == "lecture") {
      const cleanUpGetDismissals = getDismissals();
      const cleanUpGetStudentsInEvent = getStudentsInEvent();
      return () => {
        cleanUpGetDismissals();
        cleanUpGetStudentsInEvent();
      };
    }
  }, [event]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(date);
  };

  const populateClassrooms = () => {
    if (event.classroom_names)
      return event.classroom_names.map((classroom, index) => {
        return (
          <span key={"classroomName" + index}>
            {classroom} {index != event.classroom_names.length - 1 ? "," : ""}{" "}
            <span className="spacer"></span>
          </span>
        );
      });
  };

  const populateProfessors = () => {
    if (event.professor_names) {
      return event.professor_names.map((professor, index) => {
        const [firstName, lastName] = professor.split(" ");
        const formattedName = `${firstName} ${lastName.charAt(0)}.`;

        return (
          <span key={"professorName" + index}>
            {formattedName}
            {index !== event.professor_names.length - 1 ? "," : ""}
            <span className="spacer"></span>
          </span>
        );
      });
    }
  };

  const getStudentsInEvent = () => {
    let args = { event_id: event.event_id };

    const getStudentsInEventListener = (data) => {
      setStudents(data);
    };

    const refreshStudentsInEventListener = () => {
      socketContext.socket.emit("getDismissals", args);
    };

    socketContext.socket.on("studentsInEvent", getStudentsInEventListener);
    socketContext.socket.emit("getStudentsInEvent", args);
    socketContext.socket.on(
      "refreshStudentsInEvent",
      refreshStudentsInEventListener
    );

    return () => {
      socketContext.socket.off(
        "getStudentsInEvent",
        getStudentsInEventListener
      );
      socketContext.socket.off("studentsInEvent", getStudentsInEventListener);
      socketContext.socket.off(
        "refreshStudentsInEvent",
        refreshStudentsInEventListener
      );
    };
  };

  const getDismissals = () => {
    let args = { event_id: event.event_id, date_added: selectedDate };

    const getDismissalsListener = (data) => {
      console.log(data);
      setDismissals(data);
    };

    const refreshDismissalsListener = () => {
      socketContext.socket.emit("getDismissals", args);
    };

    socketContext.socket.on(
      "dismissals" +
        event.event_id +
        selectedDate.getMonth() +
        selectedDate.getDate(),
      getDismissalsListener
    );
    socketContext.socket.emit("getDismissals", args);
    socketContext.socket.on(
      "refreshDismissals" +
        event.event_id +
        selectedDate.getMonth() +
        selectedDate.getDate(),
      refreshDismissalsListener
    );

    return () => {
      socketContext.socket.off("getDismissals", getDismissalsListener);
      socketContext.socket.off(
        "dismissals" +
          event.event_id +
          selectedDate.getMonth() +
          selectedDate.getDate(),
        getDismissalsListener
      );
      socketContext.socket.off(
        "refreshDismissals" +
          event.event_id +
          selectedDate.getMonth() +
          selectedDate.getDate(),
        refreshDismissalsListener
      );
    };
  };

  const updateDismissals = (user, dismissal) => {
    setDismissals((prevDismissals) =>
      prevDismissals.map((dis) =>
        dis.user_id === user.user_id ? { ...dis, dismissal: dismissal } : dis
      )
    );

    const dismissalDate = selectedDate;

    const body = {
      user_id: user.user_id,
      dismissal: dismissal,
      event_id: event.event_id,
      selected_date: dismissalDate,
    };
    socketContext.socket.emit("updateDismissals", body);
  };

  const populateStudents = (isCheckedIn) => {
    return students.map((student) => {
      if (
        isCheckedIn &&
        (dismissals.some(
          (obj) => obj.user_id === student.user_id && obj.dismissal === 1
        ) ||
          (dismissalInitActive &&
            !dismissals.some((obj) => obj.user_id === student.user_id)))
      ) {
        return (
          <DismissalUserItem
            key={"checkedinlUser " + student.user_id}
            type="remove"
            user={student}
            updateDismissals={updateDismissals}
          />
        );
      }
      if (
        !isCheckedIn &&
        (dismissals.some(
          (obj) => obj.user_id === student.user_id && obj.dismissal === 0
        ) ||
          (!dismissalInitActive &&
            !dismissals.some((obj) => obj.user_id === student.user_id)))
      ) {
        return (
          <DismissalUserItem
            key={"dismissallUser " + student.user_id}
            type="add"
            user={student}
            updateDismissals={updateDismissals}
          />
        );
      }
    });
  };

  const startCall = () => {
    if (!event.call_link) {
      const hash = window.crypto.randomUUID();
      const meeting_url = "https://meet.jit.si/" + hash;
      let eventBody = {
        meeting_url: meeting_url,
        event_id: event.event_id,
      };

      socketContext.socket.emit("addEventMeeting", eventBody);
    } else {
      const isRunningInWebView = () => {
        return window.ReactNativeWebView !== undefined;
      };

      const isAndroidWebView = () => {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        return /Android/.test(userAgent) && /wv/.test(userAgent);
      };

      let callLink = event.call_link;

      if (isRunningInWebView() && window.ReactNativeWebView) {
        if (isAndroidWebView()) {
          const opened = window.open(callLink, "_system");
          if (!opened) {
            // Fallback to changing the location
            window.location.href = callLink;
          }
        } else {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "OPEN_LINK",
              data: callLink,
            })
          );
        }
      } else {
        window.open(callLink, "_blank");
      }
    }
  };

  return (
    <div className="professor-event-card">
      <div className="info">
        <div
          className="professor-event-card__close"
          onClick={() => closeCard()}
        >
          <BiX
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
        </div>
        <span className="info__title">
          <span className="label">{event.title}</span>
        </span>
        <div className="info__wrapper">
          <div className="info__wrapper-details">
            <div className="item">
              <BiCalendarCheck
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {event.type == "lecture"
                    ? formatDate(selectedDate)
                    : formatDate(event.start_at)}
                  {event.type == "online-exam"
                    ? " στις " + formatTime(event.start_at)
                    : ""}
                </span>
              </div>
            </div>
            {event.type == "online-exam" ? (
              <div className="item">
                <BiCalendarX
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="date">
                    {event.type == "online-exam"
                      ? formatDate(event.finish_at) +
                        " στις " +
                        formatTime(event.finish_at)
                      : ""}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="item">
              <BiTime
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="time">
                  {event.type != "online-exam"
                    ? formatTime(event.start_at) +
                      " - " +
                      formatTime(event.finish_at)
                    : "Διάρκεια: " + event.duration + " λεπτά"}
                </span>
              </div>
            </div>
            {event.type != "parent-event" ? (
              <div className="item">
                <BiBookOpen
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="label">
                    {event.type == "lecture" ? "Μάθημα" : ""}
                    {event.type == "exam" ? "Διαγώνισμα" : ""}
                    {event.type == "online-exam"
                      ? "Ηλεκτρονικό Διαγώνισμα"
                      : ""}
                    {event.type == "test" ? "Τεστ" : ""}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            {event.repeat_type == "weekly" ? (
              <div className="item">
                <BiSync
                  size={"33px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="label">Εβδομαδιαία Επανάληψη</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {event.type == "exam" ||
            (event.type == "test" && event.classroom_names) ? (
              <div className="item">
                <BiHome
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateClassrooms()}</div>
              </div>
            ) : (
              ""
            )}
            {(event.type == "exam" ||
              event.type == "test" ||
              event.type == "online-exam") &&
            event.classroom_names ? (
              <div className="item">
                <BiUser
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateProfessors()}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {!getGeneralSettingValue(generalSettings, "lecture-general-comment") &&
      event.type == "lecture" ? (
        <ChaptersTimeline selectedDate={selectedDate} event={event} />
      ) : getGeneralSettingValue(generalSettings, "lecture-general-comment") &&
        event.type == "lecture" ? (
        <EventComment selectedDate={selectedDate} event={event} />
      ) : (
        ""
      )}
      {event.type == "lecture" ? (
        <button className="cta start-online mobile" onClick={() => startCall()}>
          <BiDesktop
            size={"25px"}
            color={"#6225e6"}
            style={iconStyle("transparent")}
          />
          <span>
            {!event.call_link
              ? "Μετατροπή σε ηλεκτρονικό μάθημα"
              : "Εκκίνηση ηλεκτρονικού μαθήματος"}
          </span>
        </button>
      ) : (
        ""
      )}
      {event.type == "lecture" ? (
        <motion.div className="dismissal">
          <div className="dismissal-users">
            <div className="wrapper">
              <div className="title">Παρών</div>
              <div className="users-list">
                {!dismissals ||
                !dismissals.length ||
                dismissals.find((dis) => dis.dismissal) ||
                dismissals.length != students.length ? (
                  ""
                ) : (
                  <span className="users-list__label">
                    Όλοι οι μαθητές είναι απόντες...
                  </span>
                )}
                <AnimatePresence>{populateStudents(true)}</AnimatePresence>
              </div>
            </div>
            <div className="wrapper dismissed-users">
              <div className="title">Απών</div>
              <div className="users-list">
                {dismissals.find((dis) => !dis.dismissal) ? (
                  ""
                ) : (
                  <span className="users-list__label">
                    Όλοι οι μαθητές είναι παρώντες...
                  </span>
                )}
                <AnimatePresence>{populateStudents(false)}</AnimatePresence>
              </div>
            </div>
          </div>
        </motion.div>
      ) : (
        ""
      )}
      {event.type == "parent-event" ? (
        <div className="parent-event__label">
          <span>Κανένας γονέας δεν έχει προγραμμάτισει ραντέβου</span>
        </div>
      ) : (
        ""
      )}
      {event.type == "lecture" ? (
        <StudentComments
          event={event}
          students={students}
          selectedDate={selectedDate}
        />
      ) : (
        ""
      )}
      {event.type == "exam" || event.type == "test" ? (
        <div className="marking general">
          <ExamRecords exam={event} selectedDate={selectedDate} />
        </div>
      ) : (
        ""
      )}
      {event.type == "online-exam" ? <OnlineExamPreview event={event} /> : ""}
    </div>
  );
}

export default ProfessorEventCard;
