import React, { useEffect } from "react";
import "./chat-popups.scss";
import { useSelector } from "react-redux";
import ChatItem from "./chatItem";
import { useChatContext } from "../../pages/WithNav/chatContext";

function ChatPopups() {
  // const openChats = useSelector((state) => state.profile.openChats);
  const profile = useSelector((state) => state.profile.value);

  const { openChats } = useChatContext();

  useEffect(() => {
    console.log(openChats);
  }, [openChats]);

  const populateChatItems = () => {
    return openChats.map((chat) => {
      console.log("chat.chat_id:", chat.chat_id, "type:", typeof chat.chat_id);
      return (
        <ChatItem
          key={"chatItem" + chat.chat_id}
          chat={chat}
          userId={profile.user_id}
        />
      );
    });
  };

  return (
    <div className="chat-popups">
      <div className="chat-popups__chats">
        {openChats && populateChatItems()}
      </div>
    </div>
  );
}

export default ChatPopups;
