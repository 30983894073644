import React, { useEffect, useState, useRef, useContext } from "react";
import "./chat-history.scss";
import { BiChat, BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../app/socket";
import { setOpenChats } from "../Navbar/navbarSlice";
import ChatItem from "../ChatPopups/chatItem";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import { useChatContext } from "../../pages/WithNav/chatContext";
import NewGroupChat from "./newGroupChat";

function ChatHistory() {
  const socketContext = useContext(SocketContext);
  const activeUsers = useSelector((state) => state.profile.onlineUsers);

  const { addToOpenChats } = useChatContext();

  const [newGroupExpanded, setNewGroupExpanded] = useState(false);

  const [historyIsOpen, setHistoryIsOpen] = useState(false);
  const historyRef = useRef(null);
  const newGroupChatRef = useRef(null);
  const iconRef = useRef(null);
  const dispatch = useDispatch();
  const openChats = useSelector((state) => state.profile.openChats);
  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const toggleHistory = () => {
    if (!historyIsOpen) {
      setHistoryIsOpen(true);
    } else {
      setHistoryIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        historyIsOpen &&
        historyRef.current &&
        iconRef.current &&
        !historyRef.current.contains(event.target) &&
        !iconRef.current.contains(event.target)
      ) {
        if (window.innerWidth > 800) {
          setHistoryIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [historyIsOpen]);

  const [searchChat, setSearchChat] = useState("");

  const [allUsers, setAllusers] = useState([]);
  const [gotAllUsers, setGotAllUsers] = useState(false);

  const [userChat, setUserChat] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [openChatItem, setOpenChatItem] = useState();

  useEffect(() => {
    const cleanUpGetAllUsers = getAllUsers();
    const cleanUpGetChatHistory = getUserChat();
    return () => {
      cleanUpGetChatHistory();
      cleanUpGetAllUsers();
    };
  }, [profile, searchChat, generalSettings]);

  useEffect(() => {
    const cleanUpUnreadMessages = getUnreadMessagesCount();
    return () => {
      cleanUpUnreadMessages();
    };
  }, [profile]);

  const getUnreadMessagesCount = () => {
    let args = { user_id: profile.user_id };

    const getUnreadMessagesListener = (data) => {
      if (data[0] && data[0].unread_chats) {
        setUnreadCount(data[0].unread_chats);
      } else {
        setUnreadCount(0);
      }
    };

    const refreshUnreadListener = () => {
      socketContext.socket.emit("getUnreadMessagesCount", args);
    };

    socketContext.socket.on(
      "unreadMessagesCount" + profile.user_id,
      getUnreadMessagesListener
    );
    socketContext.socket.emit("getUnreadMessagesCount", args);
    socketContext.socket.on(
      "refreshUnreadMessagesCount" + profile.user_id,
      refreshUnreadListener
    );

    return () => {
      socketContext.socket.off("getUnreadMessages", getUnreadMessagesListener);
      socketContext.socket.off(
        "unreadMessagesCount" + profile.user_id,
        getUnreadMessagesListener
      );
      socketContext.socket.off(
        "refreshUnreadMessagesCount" + profile.user_id,
        refreshUnreadListener
      );
    };
  };

  const getUserChat = () => {
    let args = { user_id: profile.user_id, name: searchChat };

    const getUserChatListener = (data) => {
      data.map((userChat) => {
        if (!userChat.chat_name) {
          if (userChat.first_name && userChat.last_name) {
            // userChat.chat_name = userChat.first_name + userChat.last_name;
          }
        }
        return getChatLastMsg(userChat);
      });
      setUserChat(data);
    };

    const refreshUserChatListener = () => {
      socketContext.socket.emit("getUserChat", args);
    };

    socketContext.socket.on("userChat" + profile.user_id, getUserChatListener);
    socketContext.socket.emit("getUserChat", args);
    socketContext.socket.on(
      "refreshUserChat" + profile.user_id,
      refreshUserChatListener
    );

    return () => {
      socketContext.socket.off("getUserChat", getUserChatListener);
      socketContext.socket.off(
        "userChat" + profile.user_id,
        getUserChatListener
      );
      socketContext.socket.off(
        "refreshUserChat" + profile.user_id,
        refreshUserChatListener
      );
    };
  };

  const getChatLastMsg = (chatItm) => {
    let args = { chat_id: chatItm.chat_id };

    const getChatLastMsgListener = (data) => {
      if (data[0]) {
        setUserChat((currentChats) =>
          currentChats.map((chat) =>
            chat.chat_id == chatItm.chat_id ? { ...chat, ...data[0] } : chat
          )
        );
      }
    };

    const refreshChatLastMsgListener = () => {
      socketContext.socket.emit("getChatLastMsg", args);
    };

    socketContext.socket.on(
      "chatLastMsg" + chatItm.chat_id,
      getChatLastMsgListener
    );
    socketContext.socket.emit("getChatLastMsg", args);
    socketContext.socket.on(
      "refreshChatLastMsg" + chatItm.chat_id,
      refreshChatLastMsgListener
    );

    return () => {
      socketContext.socket.off("getChatLastMsg", getChatLastMsgListener);
      socketContext.socket.off(
        "chatLastMsg" + chatItm.chat_id,
        getChatLastMsgListener
      );
      socketContext.socket.off(
        "refreshChatLastMsg" + chatItm.chat_id,
        refreshChatLastMsgListener
      );
    };
  };

  const getAllUsers = () => {
    let args = {
      user_id: profile.user_id,
      email: profile.email,
      name: searchChat,
      user_type: profile.user_type,
    };

    const getAllUsersListener = (data) => {
      setGotAllUsers(true);
      let temp = [];
      if (
        profile.user_type == "professor" &&
        getGeneralSettingValue(generalSettings, "professors_not_create")
      ) {
        data
          .filter((usr) => usr.user_type != "student")
          .map((usr) => {
            temp.push(usr);
          });
      } else {
        temp = data;
      }
      setAllusers(temp);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getUsersToChat", args);
    };

    socketContext.socket.on(
      "usersToChat" + profile.user_id,
      getAllUsersListener
    );
    socketContext.socket.emit("getUsersToChat", args);
    socketContext.socket.on(
      "refreshUsersToChat" + profile.user_id,
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getUsersToChat", getAllUsersListener);
      socketContext.socket.off(
        "usersToChat" + profile.user_id,
        getAllUsersListener
      );
      socketContext.socket.off(
        "refreshUsersToChat" + profile.user_id,
        refreshProfessorsListener
      );
    };
  };

  const populateAllUsers = () => {
    return allUsers.map((user) => {
      return (
        <div
          key={"chat-item-user-" + user.user_id}
          className="list__item"
          onClick={() => createNewChat(user)}
        >
          <div
            className={
              "list__item-img " +
              (activeUsers &&
              activeUsers.length &&
              activeUsers.some((usr) => usr.user_id == user.user_id)
                ? "active"
                : "")
            }
          >
            <img
              src={
                user.profile_picture
                  ? user.profile_picture
                  : "/resources/student.png"
              }
            />
          </div>
          <div className="info">
            <div className="name">
              <span>{user.first_name}</span>
              <span>{user.last_name}</span>
            </div>
            <div className="type">
              <span>
                {user.user_type == "student" ? "Μαθητής" : ""}
                {(user.user_type == "admin" || user.user_type == "professor") &&
                !user.is_secretary
                  ? "Καθηγητής"
                  : ""}
                {user.user_type == "parent" ? "Γονέας" : ""}
              </span>
            </div>
          </div>
        </div>
      );
    });
  };

  const createNewChat = (user) => {
    let newChat = {
      chat_id: -1,
      chat_name: user.first_name + " " + user.last_name,
      user_id: [user.user_id],
    };
    if (window.innerWidth > 800) {
      let updatedChats = { ...openChats };
      for (let key in updatedChats) {
        if (updatedChats[key].chat_id == -1) {
          delete updatedChats[key];
          break; // Assuming there's only one chat with chat_id = -1
        }
      }
      updatedChats[newChat.chat_id] = newChat;

      addToOpenChats(newChat);

      dispatch(setOpenChats(updatedChats));
      setHistoryIsOpen(false);
    } else {
      openMobileChat(newChat);
    }
  };

  const getChatActiveStatus = (chat) => {
    if (activeUsers) {
      try {
        const participants = JSON.parse(chat.participants);
        let isActive = false;

        participants.map((part) => {
          if (
            part != profile.user_id &&
            activeUsers.some((usr) => usr.user_id == part)
          ) {
            isActive = true;
          }
        });

        return isActive;
      } catch (e) {
        return false;
        console.log(e);
      }
    } else {
      return false;
    }
  };

  const populateChats = () => {
    if (userChat.length) {
      return userChat.map((chat) => {
        return (
          <div
            key={"chat-item-old" + chat.chat_id}
            className={
              "list__item " +
              (chat.sender_id == profile.user_id ||
              chat.reader_ids == profile.user_id
                ? ""
                : "not-read")
            }
            onClick={() => openChat(chat)}
          >
            <div
              className={
                "list__item-img " + (getChatActiveStatus(chat) ? "active" : "")
              }
            >
              {/* {getChatActiveStatus(chat) ? "act" : "npot"} */}
              <img
                className="bubble__profile"
                src={
                  chat.chat_picture !== "" && chat.chat_picture != null
                    ? chat.chat_picture
                    : "/resources/student.png"
                }
                alt="profile"
              />
            </div>
            <div className="info">
              {chat.is_group_chat && !chat.chat_name ? "Ομαδική συνομιλία" : ""}
              {chat.is_group_chat && chat.chat_name ? chat.chat_name : ""}
              {!chat.is_group_chat ? (
                <span className="name">
                  <span>{chat.first_name}</span>
                  <span>{chat.last_name}</span>
                </span>
              ) : (
                ""
              )}
              <span className="message">
                {chat.sender_id == profile.user_id && !chat.is_gif
                  ? "Εσείς: "
                  : ""}
                {chat.sender_id == profile.user_id && chat.is_gif
                  ? "Στείλατε ένα gif"
                  : ""}
                {chat.sender_id !== profile.user_id && chat.is_gif
                  ? "Σας έστειλε ένα gif"
                  : ""}
                {!chat.is_gif ? chat.message_content : ""}
              </span>
            </div>
          </div>
        );
      });
    } else {
      return (
        <span className="sublabel">Δεν έχεις προηγούμενες συνομιλίες</span>
      );
    }
  };

  const openChat = (chat) => {
    if (
      !chat.is_group_chat &&
      !chat.chat_name &&
      chat.first_name &&
      chat.last_name
    ) {
      chat.chat_name = chat.first_name + chat.last_name;
    }
    if (window.innerWidth > 800) {
      let updatedChats = { ...openChats };
      updatedChats[chat.chat_id] = chat;
      console.log(" calling open chat context ");
      addToOpenChats(chat);

      dispatch(setOpenChats(updatedChats));
      setHistoryIsOpen(false);
    } else {
      openMobileChat(chat);
    }
  };

  const openMobileChat = (chat) => {
    setOpenChatItem(chat);
    setChatIsOpen(true);
  };

  return (
    <div className="chat-history">
      {/* {historyIsOpen &&
      (profile.user_type == "admin" || profile.user_type == "professor") ? (
        <div className="chat-history__new" ref={newGroupChatRef}>
          <NewGroupChat
            isExpanded={newGroupExpanded}
            setIsExpanded={setNewGroupExpanded}
          />
        </div>
      ) : (
        ""
      )} */}
      <div className={"chat-history__chatItem " + (chatIsOpen ? "open" : "")}>
        <div className="header"></div>
        {openChatItem ? (
          <ChatItem
            chat={openChatItem}
            userId={profile.user_id}
            navigateBack={() => setChatIsOpen(false)}
          />
        ) : (
          ""
        )}
      </div>
      <div className="chat-history__icon" ref={iconRef} onClick={toggleHistory}>
        <BiChat
          size={"35px"}
          color={"#6225e6"}
          style={iconStyle("transparent")}
        />
        {unreadCount ? (
          <div className="count">
            <span>{unreadCount}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        ref={historyRef}
        className={"chat-history__history " + (historyIsOpen ? "active" : "")}
      >
        <div className="chat-history__history-search">
          <div
            className="back"
            onClick={() => {
              setHistoryIsOpen(false);
              console.log("closing");
            }}
          >
            <BiArrowBack
              size={"30px"}
              color={"#7e7e7e"}
              style={iconStyle("transparent")}
            />
          </div>
          <input
            value={searchChat}
            onChange={(e) => setSearchChat(e.target.value)}
            placeholder="Αναζήτηση"
          />
        </div>
        <div className="chat-history old">
          <span className="label">Συνομιλίες</span>
          <div className="list">{populateChats()}</div>
        </div>
        <div className="chat-history new">
          <span className="label">Χρήστες</span>
          <div className="list">{populateAllUsers()}</div>
        </div>
      </div>
    </div>
  );
}

export default ChatHistory;
