import React, { useEffect, useState, useContext, useRef } from "react";
import {
  BiCalendar,
  BiHome,
  BiX,
  BiBookOpen,
  BiTime,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import "./number-input.scss";

function NumberInput({ inputValue, setInputValue, label, maxValue }) {
  const updateInput = (value) => {};

  return (
    <div className="number-input">
      <span>label</span>
      <div className="number-input__input">
        <BiChevronDown
          size={"30px"}
          color={"#fff"}
          style={iconStyle("#d6d6d6")}
        />
        <input
          type="number"
          value={inputValue}
          onChange={(e) => updateInput(e.target.value)}
        />
        <BiChevronUp
          size={"30px"}
          color={"#fff"}
          style={iconStyle("#d6d6d6")}
        />
      </div>
    </div>
  );
}

export default NumberInput;
