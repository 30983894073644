import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiCalendar,
  BiHome,
  BiX,
  BiBookOpen,
  BiTime,
  BiDesktop,
  BiCheck,
} from "react-icons/bi";
import {
  getGeneralSettingValue,
  iconStyle,
} from "../../../../utils/generalUtils";
import "./parent-event-card.scss";
import StudentExamScore from "../../../StudentExamScore/studentExamScore";
import { useSelector } from "react-redux";
import ParentEvent from "../../ParentEvent/parentEvent";

const formatSqlDate = (dateString) => {
  const date = new Date(dateString);

  // Get the year, month, and day from the Date object
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Format and return the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
};

function ParentEventCard({ event, closeCard, selectedDate }) {
  const [progressLabels, setProgressLabels] = useState([
    "Καμία Προσπάθεια",
    "Ελάχιστη Προσπάθεια",
    "Μέτρια Προσπάθεια",
    "Καλή Προσπάθεια",
    "Άριστη Προσπάθεια",
  ]);
  const socketContext = useContext(SocketContext);

  const children = useSelector((state) => state.profile.children);
  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [lectureComment, setLectureComment] = useState();
  const [homework, setHomework] = useState();
  const [generalComment, setGeneralComment] = useState();
  const [comments, setComments] = useState([]);

  const [dismissals, setDismissals] = useState([]);
  const [lectureStudentProgress, setLectureStudentProgress] = useState([]);

  const [scores, setScores] = useState([]);

  useEffect(() => {
    if (window.location.hostname.includes("oramapaideias")) {
      if (
        [109, 10, 13, 16, 18, 21, 22, 24, 26, 28, 30, 31, 29, 17].includes(
          event.class_id
        )
      ) {
        setProgressLabels([
          "You can do better",
          "Good",
          "Quite Good",
          "Very Good",
          "Excellent",
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (event.type == "lecture") {
      const cleanUpGetDismissals = getDismissals();
      const cleanUpGetEventStudentComments = getStudentComments();
      const cleanUpGetLectureProgress = getLectureProgress();
      return () => {
        cleanUpGetDismissals();
        cleanUpGetLectureProgress();
        cleanUpGetEventStudentComments();
      };
    }
  }, [event]);
  useEffect(() => {
    if (event.type == "exam" || event.type == "test") {
      getExamScores();
    }
  }, [event]);

  useEffect(() => {
    const cleanUpGetEventComment = getLectureComment();
    return () => {
      cleanUpGetEventComment();
    };
  }, []);

  const getExamScores = () => {
    let args = {
      exam_id: event.id ? event.id : event.exam_id,
      exam_date:
        event.repeat_type && event.repeat_type == "weekly" ? selectedDate : "",
    };

    const getMarkedListener = (data) => {
      const temp = [];
      data.map((score) => {
        if (children.find((child) => child.user_id == score.user_id)) {
          temp.push(score);
        }
      });
      setScores(temp);
    };

    socketContext.socket.on("markedExams", getMarkedListener);
    socketContext.socket.emit("getMarkedExams", args);
    socketContext.socket.on("refreshMarkedExams", () => {
      socketContext.socket.emit("getMarkedExams", args);
    });
  };

  const getLectureProgress = () => {
    let args = { event_id: event.event_id, created_at: selectedDate };
    const getLectureStudentProgressListener = (data) => {
      const temp = [];
      data.map((comment) => {
        if (children.find((child) => child.user_id == comment.user_id)) {
          temp.push(comment);
        }
      });
      setLectureStudentProgress(temp);
    };
    const refreshLectureStudentProgressListener = () => {
      socketContext.socket.emit("getLectureStudentProgress", args);
    };
    socketContext.socket.on(
      "lectureStudentProgress" + event.event_id,
      getLectureStudentProgressListener
    );
    socketContext.socket.emit("getLectureStudentProgress", args);
    socketContext.socket.on(
      "refreshLectureStudentProgress" + event.event_id,
      refreshLectureStudentProgressListener
    );
    return () => {
      socketContext.socket.off(
        "getLectureStudentProgress",
        getLectureStudentProgressListener
      );
      socketContext.socket.off(
        "lectureStudentProgress" + event.event_id,
        getLectureStudentProgressListener
      );
      socketContext.socket.off(
        "refreshLectureStudentProgress" + event.event_id,
        refreshLectureStudentProgressListener
      );
    };
  };

  const getStudentComments = () => {
    let args = { event_id: event.event_id, created_at: selectedDate };
    const getCommentsListener = (data) => {
      const temp = [];
      data.map((comment) => {
        if (children.find((child) => child.user_id == comment.to_user_id)) {
          temp.push(comment);
        }
      });
      setComments(temp);
    };
    const refreshCommentsListener = () => {
      socketContext.socket.emit("getEventStudentComments", args);
    };
    socketContext.socket.on(
      "eventStudentComments" + event.event_id,
      getCommentsListener
    );
    socketContext.socket.emit("getEventStudentComments", args);
    socketContext.socket.on(
      "refreshEventStudentComments" + event.event_id,
      refreshCommentsListener
    );
    return () => {
      socketContext.socket.off("getEventStudentComments", getCommentsListener);
      socketContext.socket.off(
        "eventStudentComments" + event.event_id,
        getCommentsListener
      );
      socketContext.socket.off(
        "refreshEventStudentComments" + event.event_id,
        refreshCommentsListener
      );
    };
  };

  const getDismissals = () => {
    let args = { event_id: event.event_id, date_added: selectedDate };

    const getDismissalsListener = (data) => {
      const temp = [];
      data.map((dism) => {
        if (children.find((child) => child.user_id == dism.user_id)) {
          temp.push(dism);
        }
      });
      setDismissals(temp);
    };

    const refreshDismissalsListener = () => {
      socketContext.socket.emit("getDismissals", args);
    };

    socketContext.socket.on(
      "dismissals" +
        event.event_id +
        selectedDate.getMonth() +
        selectedDate.getDate(),
      getDismissalsListener
    );
    socketContext.socket.emit("getDismissals", args);
    socketContext.socket.on(
      "refreshDismissals" +
        event.event_id +
        selectedDate.getMonth() +
        selectedDate.getDate(),
      refreshDismissalsListener
    );

    return () => {
      socketContext.socket.off("getDismissals", getDismissalsListener);
      socketContext.socket.off(
        "dismissals" +
          event.event_id +
          selectedDate.getMonth() +
          selectedDate.getDate(),
        getDismissalsListener
      );
      socketContext.socket.off(
        "refreshDismissals" +
          event.event_id +
          selectedDate.getMonth() +
          selectedDate.getDate(),
        refreshDismissalsListener
      );
    };
  };

  const getLectureComment = () => {
    let args = {
      event_id: event.event_id,
      date: formatSqlDate(selectedDate),
    };

    const getLectureCommentListener = (data) => {
      if (data && data[0]) {
        data.map((comment) => {
          if (comment.comment_type == "curriculum") {
            setLectureComment(comment);
          } else if (comment.comment_type == "homework") {
            setHomework(comment);
          } else if (comment.comment_type == "general") {
            setGeneralComment(comment);
          }
        });
      }
    };

    const refreshLectureCommentListener = () => {
      socketContext.socket.emit("getLectureComment", args);
    };

    socketContext.socket.on(
      "lectureComment" + event.event_id,
      getLectureCommentListener
    );
    socketContext.socket.emit("getLectureComment", args);
    socketContext.socket.on(
      "refreshLectureComment" + event.event_id,
      refreshLectureCommentListener
    );

    return () => {
      socketContext.socket.off("getLectureComment", getLectureCommentListener);
      socketContext.socket.off(
        "lectureComment" + event.event_id,
        getLectureCommentListener
      );
      socketContext.socket.off(
        "refreshLectureComment" + event.event_id,
        refreshLectureCommentListener
      );
    };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(date);
  };

  const populateClassrooms = () => {
    return event.classroom_names.map((classroom, index) => {
      return <span key={"classroomName" + index}>{classroom}</span>;
    });
  };

  const startCall = () => {
    if (event.call_link) {
      const isRunningInWebView = () => {
        return window.ReactNativeWebView !== undefined;
      };

      const isAndroidWebView = () => {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        return /Android/.test(userAgent) && /wv/.test(userAgent);
      };

      let callLink = event.call_link;

      if (isRunningInWebView() && window.ReactNativeWebView) {
        if (isAndroidWebView()) {
          const opened = window.open(callLink, "_system");
          if (!opened) {
            // Fallback to changing the location
            window.location.href = callLink;
          }
        } else {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "OPEN_LINK",
              data: callLink,
            })
          );
        }
      } else {
        window.open(callLink, "_blank");
      }
    }
  };

  const populateChildren = () => {
    return children.map((child) => {
      const comment = comments.find((com) => com.to_user_id == child.user_id);
      console.log("dismissals");
      console.log(dismissals);
      const dismissal = dismissals.find(
        (dism) => dism.user_id == child.user_id
      );
      console.log(" for student " + child.first_name);
      console.log(dismissal);
      const progress = lectureStudentProgress.find(
        (prog) => prog.user_id == child.user_id
      );
      return (
        <div
          key={"dimissal " + child.user_id}
          className="details__children-list-item"
        >
          <div className="personal-details">
            <div className="personal-details__img">
              <img
                src={
                  child.profile_picture
                    ? child.profile_picture
                    : "resources/student.png"
                }
              />
            </div>
            <div className="personal-details__name">
              <span>{child.first_name}</span>
              <span>{child.last_name}</span>
            </div>
          </div>
          {getGeneralSettingValue(
            generalSettings,
            "parents-view-lecture-grade"
          ) ? (
            <div className="details-comment">
              {progress ? (
                <div className="details-comment__progress">
                  <span className="label">Γενική Επίδοση: </span>
                  <span className="title">
                    {progressLabels[progress.progress_grade]}
                  </span>
                </div>
              ) : (
                ""
              )}
              {comment ? (
                <div className="details-comment__comment">
                  <span className="label">Γενικά Σχόλια:</span>
                  <span className="title">{comment.description}</span>
                </div>
              ) : (
                ""
              )}
              {!comment && !progress ? (
                <span className="details-comment__empty">
                  Δεν υπάρχουν σχόλια
                </span>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="dismissal-value">
            <span className="dismissal-value__label">
              {dismissal && !dismissal.dismissal ? "Απών" : "Παρών"}
            </span>
            <div className="dismissal-value__icon">
              {dismissal && !dismissal.dismissal ? (
                <BiX
                  size={"30px"}
                  color={"#fff"}
                  style={iconStyle("#ff6f6f")}
                />
              ) : (
                <BiCheck
                  size={"30px"}
                  color={"#fff"}
                  style={iconStyle("#25e662")}
                />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const populateExamScores = () => {
    return children.map((child) => {
      let newEvent = { ...event };
      newEvent.title = child.first_name;
      let score = scores.find((sc) => sc.user_id == child.user_id);
      if (score) {
        newEvent.score = score.score;
        newEvent.detailed_score = score.detailed_score;
        newEvent.result_text = score.result_text;
      } else {
        newEvent.score = null;
      }
      return (
        <div className="exams-wrapper">
          <div className="exams-wrapper__student">
            <div className="image">
              <img
                className="profile-img__img"
                src={
                  child.profile_picture
                    ? child.profile_picture
                    : "resources/student.png"
                }
                alt={"student"}
              />
            </div>
            <div className="name">
              <span>{child.first_name}</span>
              <span>{child.last_name}</span>
            </div>
          </div>
          <StudentExamScore event={newEvent} />
        </div>
      );
    });
  };

  return (
    <div className="parent-event-card">
      <div className="parent-event-card__close" onClick={() => closeCard()}>
        <BiX
          size={"30px"}
          color={"#cccccc"}
          // color={"#fff"}
          style={iconStyle("transparent")}
        />
      </div>
      <div className="info">
        <span className="info__title">
          <span className="label">{event.title}</span>
        </span>
        <div className="info__wrapper">
          <div className="info__wrapper-details">
            <div className="item">
              <BiCalendar
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {formatDate(event.start_at)}
                  {event.type == "online-exam"
                    ? " - " + formatDate(event.finish_at)
                    : ""}
                </span>
              </div>
            </div>
            <div className="item">
              <BiTime
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="time">
                  {formatTime(event.start_at)} - {formatTime(event.finish_at)}
                </span>
              </div>
            </div>
            <div className="item">
              <BiBookOpen
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                {/* <span className="sublabel">Τύπος εξέτασης: </span> */}
                <span className="label">
                  {event.type == "exam" ? "Διαγώνισμα" : ""}
                  {event.type == "online-exam" ? "Ηλεκτρονικό Διαγώνισμα" : ""}
                  {event.type == "test" ? "Τεστ" : ""}
                </span>
              </div>
            </div>
            {event.type == "exam" ||
            (event.type == "test" && event.classroom_names) ? (
              <div className="item">
                <BiHome
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateClassrooms()}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="details">
        {event.type == "parent-event" ? <ParentEvent event={event} /> : ""}
        {/* {event.type != "lecture" ? <StudentExamScore event={event} /> : ""} */}
        {event.type == "online-exam" ? <StudentExamScore event={event} /> : ""}
        {event.type == "exam" || event.type == "test"
          ? populateExamScores()
          : ""}
        {lectureComment ? (
          <div className="details__comment">
            <span className="details__comment-label">Διδακτέα Ύλη</span>
            <span className="details__comment-title">
              {lectureComment.comment_value}
            </span>
          </div>
        ) : (
          ""
        )}
        {homework ? (
          <div className="details__comment">
            <span className="details__comment-label">
              Διάβασμα για το σπίτι
            </span>
            <span className="details__comment-title">
              {homework.comment_value}
            </span>
          </div>
        ) : (
          ""
        )}
        {generalComment ? (
          <div className="details__comment">
            <span className="details__comment-label">Γενικά Σχόλια</span>
            <span className="details__comment-title">
              {generalComment.comment_value}
            </span>
          </div>
        ) : (
          ""
        )}
        {event.type == "lecture" ? (
          <div className="details__children">
            <span className="details__children-title">Μαθητές</span>
            <div className="details__children-list">{populateChildren()}</div>
          </div>
        ) : (
          ""
        )}
        {event.type == "lecture" && event.call_link ? (
          <button
            className="cta start-online mobile"
            onClick={() => startCall()}
          >
            <BiDesktop
              size={"25px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
            <span>Εκκίνηση ηλεκτρονικού μαθήματος</span>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ParentEventCard;
