import "./tuition-section.scss";
import { useContext, useEffect, useState, useMemo } from "react";
import { SocketContext } from "../../../../app/socket";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { BiUser, BiWallet } from "react-icons/bi";
import {
  getGeneralSettingValue,
  iconStyle,
} from "../../../../utils/generalUtils";
import WeeklySlider from "./weeklySlider";
import MissingTuition from "./missing-tuition";
import { useSelector } from "react-redux";

function TuitionSection() {
  const socketContext = useContext(SocketContext);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [tuitionIsMonthly, setTuitionIsMonthly] = useState(false);
  const [monthlyTuition, setMonthlyTuition] = useState([]);
  const [totalTuition, setTotalTuition] = useState(0);
  const [totalTuitionEarned, setTotalTuitionEarned] = useState(0);
  const [detailedTuition, setDetailedTuition] = useState([]);
  const [totalTuitionForGrades, setTotalTuitionForGrades] = useState([]);
  const [expectedTuitionData, setExpectedTuitionData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [generalTuition, setGeneralTuition] = useState([]);
  const [users, setUsers] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);

  useEffect(() => {
    const cleanupGeneralSettings = getGeneralSettings();
    return () => {
      cleanupGeneralSettings();
    };
  }, []);

  useEffect(() => {
    if (tuitionIsMonthly) {
      const cleanupMonthlyTuition = getMonthlyTuition();
      return () => {
        cleanupMonthlyTuition();
      };
    } else {
      const cleanupTotalTuition = getTotalTuition();
      const cleanupTotalTuitionEarned = getTotalTuitionEarned();
      const cleanupDetailedTuition = getDetailedTuition();
      const cleanupExpectedTuitionPerMonth = getExpectedTuitionPerMonth();
      const cleanupTotalTuitionForGrades = getTotalTuitionForGrades();
      const cleanupGeneralTuition = getGeneralTuition();
      const cleanupUsers = getUsers();

      return () => {
        cleanupDetailedTuition();
        cleanupTotalTuition();
        cleanupExpectedTuitionPerMonth();
        cleanupTotalTuitionForGrades();
        cleanupTotalTuitionEarned();
        cleanupGeneralTuition();
        cleanupUsers();
      };
    }
  }, [tuitionIsMonthly]);

  useEffect(() => {
    if (detailedTuition.length > 0 && expectedTuitionData.length > 0) {
      const combinedChartData = prepareChartData(
        detailedTuition,
        expectedTuitionData
      );
      setChartData(combinedChartData);
    }
  }, [detailedTuition, expectedTuitionData]);

  const monthlyLists = useMemo(() => {
    if (users.length > 0 && generalTuition.length > 0) {
      return processTuitionData(users, generalTuition, weeklyData);
    } else {
      return {};
    }
  }, [users, generalTuition, weeklyData]);

  const getGeneralSettings = () => {
    let args = {};

    const getGeneralSettingsListener = (data) => {
      const tuitionIsMonthlySetting = data.find(
        (obj) => obj.name === "tuition_is_monthly"
      );
      if (tuitionIsMonthlySetting) {
        setTuitionIsMonthly(tuitionIsMonthlySetting.value);
      } else {
        setTuitionIsMonthly(false);
      }
    };

    const refreshGeneralSettingsListener = () => {
      socketContext.socket.emit("getGeneralSettings", args);
    };

    socketContext.socket.on("generalSettings", getGeneralSettingsListener);
    socketContext.socket.emit("getGeneralSettings", args);
    socketContext.socket.on(
      "refreshGeneralSettings",
      refreshGeneralSettingsListener
    );

    return () => {
      socketContext.socket.off("generalSettings", getGeneralSettingsListener);
      socketContext.socket.off(
        "refreshGeneralSettings",
        refreshGeneralSettingsListener
      );
    };
  };

  const getMonthlyTuition = () => {
    let args = {};
    const getMonthlyTuitionListener = (data) => {
      setMonthlyTuition(data);
    };

    socketContext.socket.on("monthlyTuition", getMonthlyTuitionListener);
    socketContext.socket.emit("getMonthlyTuition", args);

    return () => {
      socketContext.socket.off("monthlyTuition", getMonthlyTuitionListener);
    };
  };

  const getExpectedTuitionPerMonth = () => {
    let args = {};

    const getExpectedTuitionPerMonthListener = (data) => {
      setExpectedTuitionData(data);
    };

    socketContext.socket.on(
      "expectedTuitionPerMonth",
      getExpectedTuitionPerMonthListener
    );
    socketContext.socket.emit("getExpectedTuitionPerMonth", args);

    return () => {
      socketContext.socket.off(
        "expectedTuitionPerMonth",
        getExpectedTuitionPerMonthListener
      );
    };
  };

  const getDetailedTuition = () => {
    let args = { selectOnlyPayments: true };
    const getDetailedTuitionListener = (data) => {
      setDetailedTuition(data);
      setWeeklyData(data);
    };

    socketContext.socket.on("detailedTuition", getDetailedTuitionListener);
    socketContext.socket.emit("getDetailedTuition", args);

    return () => {
      socketContext.socket.off("detailedTuition", getDetailedTuitionListener);
    };
  };

  const getTotalTuitionEarned = () => {
    let args = {};
    const getTotalTuitionEarnedListener = (data) => {
      if (data) {
        setTotalTuitionEarned(data);
      }
    };

    socketContext.socket.on(
      "totalTuitionEarned",
      getTotalTuitionEarnedListener
    );
    socketContext.socket.emit("getTotalTuitionEarned", args);

    return () => {
      socketContext.socket.off(
        "totalTuitionEarned",
        getTotalTuitionEarnedListener
      );
    };
  };

  const getTotalTuition = () => {
    let args = {};
    const getTotalTuitionListener = (data) => {
      if (data) {
        setTotalTuition(data);
      }
    };

    socketContext.socket.on("totalTuition", getTotalTuitionListener);
    socketContext.socket.emit("getTotalTuition", args);

    return () => {
      socketContext.socket.off("totalTuition", getTotalTuitionListener);
    };
  };

  const getTotalTuitionForGrades = () => {
    let args = {};
    const getTotalTuitionForGradesListener = (data) => {
      data.forEach((item) => {
        if (item.total_amount < 0) {
          item.total_amount = 0;
        }
      });

      setTotalTuitionForGrades(data);
    };

    socketContext.socket.on(
      "totalTuitionForGrades",
      getTotalTuitionForGradesListener
    );
    socketContext.socket.emit("getTotalTuitionForGrades", args);

    return () => {
      socketContext.socket.off(
        "totalTuitionForGrades",
        getTotalTuitionForGradesListener
      );
    };
  };

  const getGeneralTuition = () => {
    let args = {};
    const getGeneralTuitionListener = (data) => {
      setGeneralTuition(data);
    };

    socketContext.socket.on("generalTuition", getGeneralTuitionListener);
    socketContext.socket.emit("getGeneralTuition", args);

    return () => {
      socketContext.socket.off("generalTuition", getGeneralTuitionListener);
    };
  };

  const getUsers = () => {
    let args = {};

    const getUserDataListener = (data) => {
      setUsers(data);
    };

    socketContext.socket.on("userDataForAutoProgram", getUserDataListener);
    socketContext.socket.emit("getUserDataForAutoProgram", args);

    return () => {
      socketContext.socket.off("userDataForAutoProgram", getUserDataListener);
    };
  };

  const prepareChartData = (detailedData, expectedData) => {
    const months = [
      "Ιαν",
      "Φεβ",
      "Μάρ",
      "Απρ",
      "Μάι",
      "Ιούν",
      "Ιούλ",
      "Αύγ",
      "Σεπ",
      "Οκτ",
      "Νοέ",
      "Δεκ",
    ];

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let startYear;
    let startMonthIndex;

    if (currentMonth >= 7) {
      startMonthIndex = 7; // Start from August
      startYear = currentYear;
    } else {
      startMonthIndex = 8; // Start from September
      startYear = currentYear - 1;
    }

    const rotatedMonths = [];
    for (let i = 0; i < 12; i++) {
      const monthIndex = (startMonthIndex + i) % 12;
      const year = startYear + Math.floor((startMonthIndex + i) / 12);
      rotatedMonths.push(`${months[monthIndex]} ${year}`);
    }

    // Initialize arrays for each payment method
    let cashSums = Array(12).fill(0);
    let bankSums = Array(12).fill(0);
    let cardSums = Array(12).fill(0);
    let expectedSums = Array(12).fill(0);

    // Calculate the sums based on the payment method
    detailedData.forEach((item) => {
      const date = new Date(item.time);
      const itemYear = date.getFullYear();
      const itemMonth = date.getMonth();

      rotatedMonths.forEach((label, index) => {
        const [labelMonth, labelYear] = label.split(" ");
        const labelMonthIndex = months.indexOf(labelMonth);

        if (itemYear === parseInt(labelYear) && itemMonth === labelMonthIndex) {
          if (item.payment_method === "cash") {
            cashSums[index] += item.amount;
          } else if (item.payment_method === "bank") {
            bankSums[index] += item.amount;
          } else if (item.payment_method === "card") {
            cardSums[index] += item.amount;
          }
        }
      });
    });

    // Expected tuition sums calculation
    if (expectedData) {
      expectedData.forEach((item) => {
        const [itemYear, itemMonth] = item.month_year.split("-").map(Number);

        rotatedMonths.forEach((label, index) => {
          const [labelMonth, labelYear] = label.split(" ");
          const labelMonthIndex = months.indexOf(labelMonth);

          if (
            itemYear === parseInt(labelYear) &&
            itemMonth - 1 === labelMonthIndex
          ) {
            expectedSums[index] += item.total_expected;
          }
        });
      });
    }

    // Compute total sums
    let totalSums = [];
    for (let i = 0; i < 12; i++) {
      totalSums[i] = cashSums[i] + bankSums[i] + cardSums[i];
    }

    // Return chart data with separate datasets for each payment method and total payments
    return {
      labels: rotatedMonths,
      datasets: [
        {
          label: "Πληρωμές με Μετρητά",
          data: cashSums,
          backgroundColor: "#33cccc",
          borderColor: "#33cccc",
          borderWidth: 1,
          borderRadius: 10,
        },
        {
          label: "Πληρωμές με Τράπεζα",
          data: bankSums,
          backgroundColor: "#33cccc",
          borderColor: "#33cccc",
          borderWidth: 1,
          borderRadius: 10,
        },
        {
          label: "Πληρωμές με Κάρτα",
          data: cardSums,
          backgroundColor: "#25e662",
          borderColor: "#25e662",
          borderWidth: 1,
          borderRadius: 10,
        },
        {
          label: "Σύνολο Πληρωμών",
          data: totalSums,
          backgroundColor: "#6225e6",
          borderColor: "#6225e6",
          borderWidth: 1,
          borderRadius: 10,
        },
        {
          label: "Αναμενόμενα Δίδακτρα",
          data: expectedSums,
          backgroundColor: "#ffd53d92",
          borderColor: "#ffd43d",
          borderWidth: 1,
          borderRadius: 10,
        },
      ],
    };
  };

  function processTuitionData(users, generalTuition, studentPayments) {
    // Clone data to prevent state mutations
    users = users.map((user) => ({ ...user }));
    generalTuition = generalTuition.map((tuition) => ({ ...tuition }));
    studentPayments = studentPayments.map((payment) => ({ ...payment }));

    // Create a map from user_id to user object
    let userMap = {};
    users.forEach((user) => {
      if (user && user.user_id) {
        userMap[user.user_id] = user;
      }
    });

    // Create a map from student_tuition_id to tuition data
    let tuitionMap = {};
    generalTuition.forEach((tuition) => {
      let instalments = [];
      let amounts = tuition.instalments_amount.map((a) => parseFloat(a));
      let dues = tuition.instalments_due.map((d) => {
        // Parse date in format "DD/MM/YYYY"
        const [day, month, year] = d.split("/");
        return new Date(year, month - 1, day);
      });
      for (let i = 0; i < amounts.length; i++) {
        instalments.push({
          amount_due: amounts[i],
          due_date: dues[i],
          amount_paid: 0,
        });
      }
      if (!tuitionMap[tuition.student_tuition_id]) {
        tuitionMap[tuition.student_tuition_id] = {
          student_id: tuition.student_id,
          instalments: instalments,
        };
      } else {
        // Concatenate instalments if multiple entries
        tuitionMap[tuition.student_tuition_id].instalments =
          tuitionMap[tuition.student_tuition_id].instalments.concat(
            instalments
          );
      }
    });

    // Map payments to tuitions
    let paymentsMap = {};
    studentPayments.forEach((payment) => {
      if (!paymentsMap[payment.tuition_id]) {
        paymentsMap[payment.tuition_id] = [];
      }
      paymentsMap[payment.tuition_id].push({
        amount: parseFloat(payment.amount),
        time: new Date(payment.time),
      });
    });

    // Distribute payments to instalments
    Object.keys(tuitionMap).forEach((tuition_id) => {
      let tuition = tuitionMap[tuition_id];
      let payments = paymentsMap[tuition_id] || [];
      payments.sort((a, b) => a.time - b.time);
      allocatePayments(tuition, payments);
    });

    // Build monthly lists
    let monthlyLists = {};

    Object.values(tuitionMap).forEach((tuition) => {
      let student_id = tuition.student_id;
      let user = userMap[student_id];

      tuition.instalments.forEach((instalment) => {
        let dueDate = instalment.due_date;
        let monthKey =
          dueDate.getFullYear() +
          "-" +
          ("0" + (dueDate.getMonth() + 1)).slice(-2);

        if (!monthlyLists[monthKey]) {
          monthlyLists[monthKey] = { paid: [], unpaid: [] };
        }

        let amount_paid_display =
          instalment.amount_paid + " / " + instalment.amount_due;

        if (instalment.amount_paid >= instalment.amount_due) {
          // Paid
          monthlyLists[monthKey].paid.push({
            user: user,
            amount_paid: amount_paid_display,
          });
        } else {
          // Unpaid
          monthlyLists[monthKey].unpaid.push({
            user: user,
            amount_paid: amount_paid_display,
          });
        }
      });
    });

    return monthlyLists;
  }

  function allocatePayments(tuition, payments) {
    // Clone instalments to avoid mutating state
    tuition = {
      ...tuition,
      instalments: tuition.instalments.map((i) => ({ ...i })),
    };

    let instalments = tuition.instalments;
    let balance = 0;
    let instalmentIndex = 0;

    payments.forEach((payment) => {
      let amount = payment.amount;

      while (amount > 0 && instalmentIndex < instalments.length) {
        let instalment = instalments[instalmentIndex];
        let amount_needed = instalment.amount_due - instalment.amount_paid;

        if (amount_needed <= 0) {
          instalmentIndex++;
          continue;
        }

        let amount_used = Math.min(amount, amount_needed);
        instalment.amount_paid += amount_used;
        amount -= amount_used;

        if (instalment.amount_paid >= instalment.amount_due) {
          instalmentIndex++;
        }
      }

      if (amount > 0) {
        balance += amount;
      }
    });

    // Use any remaining balance for future instalments
    while (balance > 0 && instalmentIndex < instalments.length) {
      let instalment = instalments[instalmentIndex];
      let amount_needed = instalment.amount_due - instalment.amount_paid;

      if (amount_needed <= 0) {
        instalmentIndex++;
        continue;
      }

      let amount_used = Math.min(balance, amount_needed);
      instalment.amount_paid += amount_used;
      balance -= amount_used;

      if (instalment.amount_paid >= instalment.amount_due) {
        instalmentIndex++;
      }
    }
  }

  const donutData = {
    labels: [
      `Προβλεπόμενα Δίδακτρα ${totalTuition}€`,
      `Πληρωμένα Δίδακτρα ${totalTuitionEarned}€`,
    ],
    datasets: [
      {
        data: [totalTuition, totalTuitionEarned],
        backgroundColor: ["#ffd53d92", "#6124e681"],
        borderColor: ["#ffd43d", "#6225e6"],
        borderWidth: 1,
      },
    ],
  };

  const donutOptions = {
    cutoutPercentage: 50,
    responsive: true,
    maintainAspectRatio: false,
  };

  const tuitionForGradesData = {
    labels: totalTuitionForGrades.map((item) => item.grade_name),
    datasets: [
      {
        label: "Συνολικά Δίδακτρα",
        data: totalTuitionForGrades.map((item) => item.total_amount),
        backgroundColor: "#6124e681",
        borderColor: "#6225e6",
        borderWidth: 1,
        borderRadius: 10,
      },
    ],
  };

  const tuitionForGradesOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const getMonthNameFromKey = (monthKey) => {
    const [year, month] = monthKey.split("-");
    const monthIndex = parseInt(month, 10) - 1;
    const months = [
      "Ιανουαρίου",
      "Φεβρουαρίου",
      "Μαρτίου",
      "Απριλίου",
      "Μαΐου",
      "Ιουνίου",
      "Ιουλίου",
      "Αυγούστου",
      "Σεπτεμβρίου",
      "Οκτωβρίου",
      "Νοεμβρίου",
      "Δεκεμβρίου",
    ];
    return months[monthIndex] + " " + year;
  };

  return (
    <div
      className={
        "section tuition-section " + (tuitionIsMonthly ? "d-none" : "")
      }
    >
      <span className="section__title">Δίδακτρα</span>
      <div className="tuition-section__general">
        <div className="item">
          <div className="item__icon">
            <BiUser style={iconStyle("#6225E6")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Αναμ. Ετήσιος Τζίρος</span>
            <span className="label">
              {new Intl.NumberFormat("de-DE").format(totalTuition)}€
            </span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiWallet style={iconStyle("#FFD43D")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Συν. Εισπράξεις</span>
            <span className="label">
              {new Intl.NumberFormat("de-DE").format(totalTuitionEarned)}€
            </span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiWallet style={iconStyle("#ff9797")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Αναμ. Εισπράξεις</span>
            <span className="label">
              {new Intl.NumberFormat("de-DE").format(
                totalTuition - totalTuitionEarned
              )}
              €
            </span>
          </div>
        </div>
      </div>
      <div className="tuition-stats">
        <div className="tuition-stats__payments">
          <div className="graph graph-container">
            <span className="label">Σύνολο διδάκτρων ανά μήνα</span>
            {chartData.labels.length > 0 && chartData.datasets.length > 0 ? (
              <Bar data={chartData} />
            ) : (
              <div>Loading chart...</div>
            )}
          </div>
          {getGeneralSettingValue(generalSettings, "tuition_on_steroids") ? (
            <MissingTuition studentPayments={weeklyData} users={users} />
          ) : null}
          <WeeklySlider studentTuition={weeklyData} users={users} />
          <div className="general">
            <div className="grades graph-container">
              <span className="label">Σύνολο διδάκτρων ανά τάξη</span>
              {tuitionForGradesData.labels.length > 0 ? (
                <Bar
                  data={tuitionForGradesData}
                  options={tuitionForGradesOptions}
                />
              ) : (
                <div>Loading grade data...</div>
              )}
            </div>
            <div className="total graph-container">
              {donutData && donutOptions ? (
                <Pie data={donutData} options={donutOptions} />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
        <div className="tuition-stats__users"></div>
      </div>
      <div className="monthly-lists">
        {Object.keys(monthlyLists)
          .sort()
          .map((monthKey) => (
            <div key={monthKey} className="month-section">
              <h3>{getMonthNameFromKey(monthKey)}</h3>
              <div className="paid-list">
                <h4>Μαθητές που έχουν πληρώσει</h4>
                <ul>
                  {monthlyLists[monthKey].paid.map((item, index) => (
                    <li key={index}>
                      {item.user
                        ? `${item.user.first_name || ""} ${
                            item.user.last_name || ""
                          }`
                        : "Unknown student"}{" "}
                      - {item.amount_paid}€
                    </li>
                  ))}
                </ul>
              </div>
              <div className="unpaid-list">
                <h4>Μαθητές που δεν έχουν πληρώσει</h4>
                <ul>
                  {monthlyLists[monthKey].unpaid.map((item, index) => (
                    <li key={index}>
                      {item.user
                        ? `${item.user.first_name || ""} ${
                            item.user.last_name || ""
                          }`
                        : "Unknown student"}{" "}
                      - {item.amount_paid}€
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TuitionSection;
