import React, { createContext, useState, useContext, useEffect } from "react";

// Step 1: Create the context
export const ChatContext = createContext();

// Step 2: Create a provider component
export const ChatProvider = ({ children }) => {
  const [openChats, setOpenChats] = useState([]);

  const closeChatId = (chatId) => {
    if (openChats && openChats.length) {
      setOpenChats((prevChats) =>
        prevChats.filter((chat) => chat.chat_id !== chatId)
      );
    }
  };

  const addToOpenChats = (newChat) => {
    if (!openChats.find((cht) => cht.chat_id == newChat.chat_id)) {
      setOpenChats((prevChats) => [...prevChats, newChat]);
    }
  };

  // **Add the updateChatId function here**
  const updateChatId = (oldChatId, newChatId) => {
    setOpenChats((prevChats) =>
      prevChats.map((chat) => {
        if (chat.chat_id === oldChatId) {
          return { ...chat, chat_id: newChatId };
        }
        return chat;
      })
    );
  };

  return (
    <ChatContext.Provider
      value={{
        openChats,
        setOpenChats,
        closeChatId,
        addToOpenChats,
        updateChatId, // **Include updateChatId in the context value**
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

// Step 3: Create a custom hook for easier consumption of the context
export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
