import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import { motion } from "framer-motion";
import "./general-test.scss";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import GeneralTestUser from "./generalTestUser";
import StudentsModal from "./studentsModal/studentsModal";

function formatDateInGreek(date) {
  const monthsInGreek = [
    "Ιανουαρίου",
    "Φεβρουαρίου",
    "Μαρτίου",
    "Απριλίου",
    "Μαΐου",
    "Ιουνίου",
    "Ιουλίου",
    "Αυγούστου",
    "Σεπτεμβρίου",
    "Οκτωβρίου",
    "Νοεμβρίου",
    "Δεκεμβρίου",
  ];

  const day = date.getDate();
  const month = monthsInGreek[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

function GeneralTest({ selectedDate }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const children = useSelector((state) => state.profile.children);

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(90);
  const [modalLeft, setModalLeft] = useState(0.05);
  const [modalTop, setModalTop] = useState(0.075);

  useEffect(() => {
    if (isExpanded) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
    } else {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    if (window.innerWidth < 800) {
      setModalHeight(93);
      setModalWidth(90);
      setModalTop(0.05);
      setModalLeft(0.03);
    } else {
      setModalHeight(85);
      setModalWidth(90);
      setModalTop(0.075);
      setModalLeft(0.05);
    }
  }, []);

  const openCard = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;
      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
      };
      if (modalHeight) {
        newPositionStyle.height = modalHeight + "vh";
      } else {
        newPositionStyle.height = "auto";
      }
      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [modalExpanded, setModalExpanded] = useState(false);

  const [markedTests, setMarkedTests] = useState([]);

  useEffect(() => {
    return getUsers();
  }, [search]);

  useEffect(() => {
    const cleanUpGetGrades = getGrades();
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetGrades();
      cleanUpGetClasses();
    };
  }, []);

  useEffect(() => {
    const cleanUpGetMarkedTests = getMarkedTests();
    return () => {
      cleanUpGetMarkedTests();
    };
  }, [selectedDate]);

  const getMarkedTests = () => {
    let args = { selected_date: selectedDate };
    const getMarkedExamsListener = (data) => {
      console.log("marked tests");
      console.log(data);
      setMarkedTests(data);
    };

    socketContext.socket.on("generalTests", getMarkedExamsListener);
    socketContext.socket.emit("getGeneralTests", args);

    const refreshGeneraltestsListener = () => {
      socketContext.socket.emit("getGeneralTests", args);
    };
    socketContext.socket.on("refreshGeneralTests", refreshGeneraltestsListener);

    return () => {
      socketContext.socket.off("getGeneralTests", getMarkedExamsListener);
      socketContext.socket.off("generalTests", getMarkedExamsListener);
      socketContext.socket.off(
        "refreshGeneralTests",
        refreshGeneraltestsListener
      );
    };
  };

  const getGrades = () => {
    let args = { order_by_id: true };
    const getGradesListener = (data) => {
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);

    const refreshGradesListener = () => {
      socketContext.socket.emit("getGrades", args);
    };
    socketContext.socket.on("refreshGrades", refreshGradesListener);

    return () => {
      socketContext.socket.off("getGrades", getGradesListener);
      socketContext.socket.off("grades", getGradesListener);
      socketContext.socket.off("refreshGrades", refreshGradesListener);
    };
  };

  const getClasses = () => {
    const uniqe_id = "daily-test-all";
    let args = {
      unique_id: uniqe_id,
    };

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes" + uniqe_id, getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);

    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes" + uniqe_id, getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const getUsers = () => {
    console.log("getting users");
    const uniqueId = "general-test-users-marked";
    const args = {
      uniqe_id: uniqueId,
      name: search,
      type: "student",
    };

    const getUsersListener = (data) => {
      console.log(" got users");
      console.log(data);
      setUsers(data);
    };

    const refreshUsersListener = () => {};

    socketContext.socket.on("allUsersWithParams" + uniqueId, getUsersListener);
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on("refreshAllUsersWithParams", refreshUsersListener);

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getUsersListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshUsersListener
      );
    };
  };

  const populateUsers = () => {
    return markedTests.map((test, index) => {
      const student = users.find((usr) => usr.user_id == test.student_id);
      const classItem = classes.find((cls) => cls.class_id == test.class_id);
      if (
        student &&
        (profile.user_type == "admin" || profile.user_type == "professor")
      ) {
        return (
          <GeneralTestUser
            key={"generaltestuser" + test.general_test_id}
            user={student}
            selectedDate={selectedDate}
            test={test}
            classItem={classItem}
          />
        );
      }
      if (
        student &&
        profile.user_type == "student" &&
        profile.user_id == student.user_id
      ) {
        return (
          <GeneralTestUser
            key={"generaltestuser" + test.general_test_id}
            user={student}
            selectedDate={selectedDate}
            test={test}
            classItem={classItem}
          />
        );
      }
      if (
        student &&
        profile.user_type == "parent" &&
        children.find((child) => child.user_id == student.user_id)
      ) {
        return (
          <GeneralTestUser
            key={"generaltestuser" + test.general_test_id}
            user={student}
            selectedDate={selectedDate}
            test={test}
            classItem={classItem}
          />
        );
      }
    });
  };

  return (
    <div
      ref={containerRef}
      className={
        "event-item test " + (isExpanded ? " is-expanded " : " is-collapsed  ")
      }
    >
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        initial={false}
        className={
          "event-item-content general-test " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div className="general-test__content">
            <div className="general-test__header">
              <span>Ημερήσιο Τεστ - {formatDateInGreek(selectedDate)}</span>
            </div>
            {profile.user_type == "admin" ||
            profile.user_type == "professor" ? (
              <div className="general-test__add">
                <StudentsModal
                  isExpanded={modalExpanded}
                  setIsExpanded={setModalExpanded}
                  markedTests={markedTests}
                  selectedDate={selectedDate}
                />
              </div>
            ) : (
              ""
            )}
            <div className="general-test__users">
              {profile.user_type == "professor" ||
              profile.user_type == "admin" ? (
                <div className="general-test__title">
                  <span>Βαθμολογημένοι Μαθητές</span>
                </div>
              ) : (
                <div className="general-test__title student">
                  <span>Βαθμολογημένοι Τεστ</span>
                </div>
              )}
              <div className="general-test__students">{populateUsers()}</div>
            </div>
          </div>
        ) : (
          <div className="general-test__preview">
            <span>Ημερήσιο Τεστ - {formatDateInGreek(selectedDate)}</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default GeneralTest;
