import React, { useEffect, useState, useContext } from "react";
import { SocketContext } from "../../../app/socket";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";

function ReadingRoomUser({ user, selectedDate }) {
  const combineDateAndTime = (date, time) => {
    const [hours, minutes] = time.split(":");
    const dateObj = new Date(date);

    // Set the local time based on the selected input time (in hours and minutes)
    dateObj.setHours(hours);
    dateObj.setMinutes(minutes);
    dateObj.setSeconds(0); // Make sure to zero the seconds

    // Manually construct the date and time string without converting to UTC
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hour = String(dateObj.getHours()).padStart(2, "0");
    const minute = String(dateObj.getMinutes()).padStart(2, "0");

    // Return the formatted string in 'YYYY-MM-DD HH:MM:SS' format
    return `${year}-${month}-${day} ${hour}:${minute}:00`;
  };

  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [readingValue, setReadingValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState("");
  const [startAt, setStartAt] = useState("");
  const [finishAt, setFinishAt] = useState("");

  useEffect(() => {
    const cleanUpGetReadingValue = getReadingRoomValue();
    return () => {
      cleanUpGetReadingValue();
    };
  }, [user]);

  useEffect(() => {
    if (readingValue) {
      setComment(readingValue.comment);
      setStartAt(formatTime(readingValue.start_at));
      setFinishAt(formatTime(readingValue.finish_at));
    } else {
      // When readingValue is null, set default start and finish times
      const currentDate = new Date();
      const currentHour = currentDate.getHours();

      if (currentHour >= 22) {
        setStartAt("22:00");
        setFinishAt("23:00");
      } else {
        const startHour = currentDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        const finishDate = new Date(currentDate.getTime() + 60 * 60 * 1000); // +1 hour
        const finishHour = finishDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });

        setStartAt(startHour);
        setFinishAt(finishHour);
      }
    }
  }, [readingValue]);

  const getReadingRoomValue = () => {
    const uniqueId = "readin-room-value" + user.user_id;
    const args = {
      unique_id: uniqueId,
      user_id: user.user_id,
      date: selectedDate,
    };

    const getReadingRoomValueListener = (data) => {
      if (data && data.length) {
        console.log(data);
        if (data[0]) {
          setReadingValue(data[0]);
        }
      }
    };

    const refreshReadingRoomValueListener = () => {
      console.log("refreshed");
      socketContext.socket.emit("getReadingRoomValue", args);
    };

    socketContext.socket.on(
      "readingRoomValue" + uniqueId,
      getReadingRoomValueListener
    );
    socketContext.socket.emit("getReadingRoomValue", args);
    socketContext.socket.on(
      "refreshReadingRoomValue" + uniqueId,
      refreshReadingRoomValueListener
    );

    return () => {
      socketContext.socket.off(
        "getReadingRoomValue",
        getReadingRoomValueListener
      );
      socketContext.socket.off(
        "readingRoomValue" + uniqueId,
        getReadingRoomValueListener
      );
      socketContext.socket.off(
        "refreshReadingRoomValue" + uniqueId,
        refreshReadingRoomValueListener
      );
    };
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const handleSave = () => {
    const startAtDate = combineDateAndTime(selectedDate, startAt);
    const finishAtDate = combineDateAndTime(selectedDate, finishAt);
    const uniqueId = "readin-room-value" + user.user_id;

    let body = {
      unique_id: uniqueId,
      user_id: user.user_id,
      comment: comment,
      start_at: startAtDate,
      finish_at: finishAtDate,
      date: selectedDate,
    };

    console.log("Saving new reading room value:", body);
    socketContext.socket.emit("addReadingRoomValue", body);
  };

  const handleUpdate = () => {
    const uniqueId = "readin-room-value" + user.user_id;
    const startAtDate = combineDateAndTime(selectedDate, startAt);
    const finishAtDate = combineDateAndTime(selectedDate, finishAt);

    let body = {
      unique_id: uniqueId,
      reading_room_id: readingValue.reading_room_id,
      comment: comment,
      start_at: startAtDate,
      finish_at: finishAtDate,
    };

    console.log("Updating reading room value:", body);
    setIsEditing(false);
    socketContext.socket.emit("updateReadingRoomValue", body);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div
      className={"reading-room__users-item " + (readingValue ? "active" : "")}
    >
      <div className="user-container">
        <div className="user-container__img">
          <img
            className="profile-img__img image__open__full"
            src={
              user.profile_picture
                ? user.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="user-container__name">
          <span>{user.first_name}</span>
          <span>{user.last_name}</span>
        </div>
      </div>

      <div className="comment-container">
        <span className="comment-container__label">Σχόλιο</span>
        {isEditing || readingValue == null ? (
          <textarea
            className="input"
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Εισάγετε γενικό σχόλιο"
          ></textarea>
        ) : (
          <span className="comment-container__content">
            {readingValue.comment}
          </span>
        )}
      </div>

      <div className="time-container">
        {isEditing || readingValue === null ? (
          <>
            <div className="time-container__wrapper">
              <span>
                Ώρα έναρξης:
                <input
                  className="input"
                  type="time"
                  value={startAt}
                  onChange={(e) => setStartAt(e.target.value)}
                />
              </span>
            </div>
            <div className="time-container__wrapper">
              <span>
                Ώρα λήξης:
                <input
                  className="input"
                  type="time"
                  value={finishAt}
                  onChange={(e) => setFinishAt(e.target.value)}
                />
              </span>
            </div>
          </>
        ) : (
          <div className="time-container__content">
            <span>
              Ώρα έναρξης: <span>{formatTime(readingValue.start_at)}</span>
            </span>
            <span>
              Ώρα λήξης: <span>{formatTime(readingValue.finish_at)}</span>
            </span>
          </div>
        )}
      </div>
      <div className="controls">
        {readingValue === null && (
          <button className="cta" onClick={handleSave}>
            Αποθήκευση
          </button>
        )}

        {readingValue !== null && !isEditing && (
          <button className="cta" onClick={handleEdit}>
            Επεξεργασία
          </button>
        )}

        {isEditing && (
          <>
            <button className="cta cta-red" onClick={handleCancel}>
              Ακύρωση
            </button>
            <button className="cta cta-fill" onClick={handleUpdate}>
              Αποθήκευση
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ReadingRoomUser;
